import React from 'react';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import {
	setDialogActivity,
	setDialogActivityKey,
} from '../../redux/dialogs/dialogs.actions';

/* Material-UI */

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

/* Component Dependencies */

import DialogActivity from '../Dialog/DialogActivity';

/* Styled Components */

import styled from 'styled-components';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function AddActivity({ experience }) {
	const dispatch = useDispatch();
	const open = useSelector((state) => state.dialogs.activity);

	const handleOpen = () => {
		dispatch(setDialogActivityKey(experience));
		dispatch(setDialogActivity(true));
	};

	const handleClose = () => {
		dispatch(setDialogActivity(false));
	};

	return (
		<React.Fragment>
			<Container>
				<AddButton onClick={handleOpen} size='medium' variant='extended'>
					<Add />
					Voeg activiteit toe
				</AddButton>
			</Container>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<NavBar>
					<NavContent>
						<DialogLeftContainer>
							<IconButton
								edge='start'
								color='inherit'
								onClick={handleClose}
								aria-label='close'
							>
								<CloseDialog />
							</IconButton>
							<DialogTitle>Activiteit toevoegen</DialogTitle>
						</DialogLeftContainer>
					</NavContent>
				</NavBar>
				<DialogActivity />
			</Dialog>
		</React.Fragment>
	);
}

export default AddActivity;

const Container = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-start;
	margin: 0 0 40px 40px;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		margin: 0 0 40px 20px;
	}
`;

const AddButton = styled(Fab)`
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.tiny};

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		font-size: ${(props) => props.theme.fontSize.timi};
	}
`;

const Add = styled(AddIcon)`
	margin-right: 4px;
	font-size: ${(props) => props.theme.fontSize.large};
`;

const NavBar = styled(AppBar)`
	display: flex;
	align-items: center;
	background-color: ${(props) => props.theme.backgroundColor.secondaryClose};
`;

const NavContent = styled(Toolbar)`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const DialogLeftContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: 30px;
`;

const CloseDialog = styled(CloseIcon)`
	color: ${(props) => props.theme.backgroundColor.primary};
`;

const DialogTitle = styled.p`
	color: ${(props) => props.theme.backgroundColor.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.regular};
	font-size: ${(props) => props.theme.fontSize.huge};
	margin-left: 10px;
`;
