import { ExperienceActionTypes } from './experience.types';

export const addExperience = (experience) => ({
	type: ExperienceActionTypes.ADD_EXPERIENCE,
	payload: experience,
});

export const removeExperience = (payload) => ({
	type: ExperienceActionTypes.REMOVE_EXPERIENCE,
	payload: payload,
});
