import React, { useEffect } from 'react';

/* Backend Functionality */

import { Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from './redux/user/user.actions';

/* FIREBASE */

import { auth } from './utils/firebase';

/* Style Components */
import { StylesProvider } from '@material-ui/core/styles';

/* Component Dependencies */

import PrivateRoute from './components/PrivateRoute';
import Navigation from './components/Navigation/Navigation';

/* Pages */

import HomePage from './pages/home';
import ResumePage from './pages/resume';
import AuthPage from './pages/auth';

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		auth.onAuthStateChanged((user) => dispatch(setUser(user)));
	}, []);

	return (
		<StylesProvider injectFirst>
			<Navigation />
			<Switch>
				<Route exact path='/' component={HomePage} />
				<Route exact path='/auth' component={AuthPage} />
				<PrivateRoute path='/profile/:userId' component={ResumePage} />
				<Route path='/public/:userId' component={ResumePage} />
			</Switch>
		</StylesProvider>
	);
}

export default App;
