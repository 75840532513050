import { ActionTypes } from './activities.types';

const INITIAL_STATE = [];

const activitiesReducer = (state = INITIAL_STATE, action) => {
	const idx = state.findIndex((x) => x.id === action.payload.id);

	switch (action.type) {
		case ActionTypes.ADD_PROJECT:
			if (idx === -1) {
				return [...state, action.payload].filter(
					(item, index, arr) =>
						arr.findIndex((obj) => obj.id === item.id) === index
				);
			} else {
				return state;
			}

		case ActionTypes.UPDATE_PROJECT:
			return [
				...state.filter((obj) => obj.id !== action.payload.id),
				{ ...state[idx], ...action.payload },
			];

		case ActionTypes.REMOVE_PROJECT:
			return [...state].filter((obj) => obj.id !== action.payload);

		default:
			return state;
	}
};

export default activitiesReducer;
