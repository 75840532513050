import firebase from 'firebase';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyCHbzLG9n3sSF2ZInv12b5k5cPQr6L3IPI',
	authDomain: 'busy-bee-ba0f4.firebaseapp.com',
	projectId: 'busy-bee-ba0f4',
	storageBucket: 'busy-bee-ba0f4.appspot.com',
	messagingSenderId: '551400550303',
	appId: '1:551400550303:web:fde82126901a20c54297aa',
	measurementId: 'G-VXRJ3SXZMX',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebaseApp.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
const functions = firebase.functions();

export { firestore, auth, storage, functions };
