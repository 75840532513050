import React, { useState, forwardRef } from 'react';
import { memo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
	setActivityConfirmation,
	setActivityId,
	setExperienceId,
} from '../../redux/activitySingle/activitySingle.actions';

import { useParams } from 'react-router-dom';

/* Component Dependencies */
import { TextRegular } from '../Core/Text';

import SkillButton from './SkillButton';

/* Styled Components */
import EditIcon from '@material-ui/icons/Edit';
import BusinessIcon from '@material-ui/icons/Business';
import styled from 'styled-components';

const ActivityProject = ({
	title,
	client,
	description,
	skills,
	experience,
	id,
	provided,
	innerRef,
}) => {
	const { userId } = useParams();

	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);

	const handleClick = (id) => {
		dispatch(setActivityId(id));
		dispatch(setExperienceId(experience));
		dispatch(setActivityConfirmation(true));
	};

	const [isHover, setIsHover] = useState(false);

	return (
		<Activity
			isAuth={user?.uid === userId}
			ref={innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
		>
			<ActivityInformation
				onMouseOver={() => user?.uid === userId && setIsHover(true)}
				onMouseLeave={() => user?.uid === userId && setIsHover(false)}
				onDoubleClick={() => user?.uid === userId && handleClick(id)}
			>
				<ActivitySubtitle hasClient={!client ? false : true}>
					<Icon
						isHover={isHover}
						onClick={() => user?.uid === userId && handleClick(id)}
					>
						<EditIcon />
					</Icon>
					{title}
				</ActivitySubtitle>
				{client && (
					<ActivityClient>
						<BusinessIcon />
						{client}
					</ActivityClient>
				)}
				<TextRegular padding='10px 0 10px 0'>{description}</TextRegular>
				<ActivitySkills>
					{skills.map((skill, idx) => (
						<SkillButton key={'skill-' + idx}>{skill}</SkillButton>
					))}
				</ActivitySkills>
			</ActivityInformation>
		</Activity>
	);
};

export default ActivityProject;

const Activity = styled.div`
	display: flex;
	width: 100%;
	${(props) => props.isAuth && `cursor: move;`}
`;

const ActivityInformation = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 40px;
	width: 100%;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 20px;
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		padding-left: 10px;
	}
`;

const ActivitySubtitle = styled.div`
	display: flex;
	align-items: center;
	width: calc(100% - 20px);
	height: 10px;
	color: ${(props) => props.theme.color.busybeeMain};
	margin: 5px 0;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	transition: all 150ms ease-in;
	font-size: ${(props) => props.theme.fontSize.small};
	line-height: 2;

	${(props) => !props.hasClient && `padding-bottom:10px;`}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		font-size: ${(props) => props.theme.fontSize.tiny};
	}
`;

const ActivityClient = styled.p`
	display: flex;
	align-items: bottom;
	margin-bottom: 0;
	padding: 0;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.small};
	line-height: 2;

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		font-size: ${(props) => props.theme.fontSize.tiny};
	}

	& > svg {
		margin-right: 10px;
	}
`;

const ActivitySkills = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 40px;
`;

const Icon = styled.div`
	display: flex;
	height: 100%;
	width: ${(props) => (props.isHover ? `30px` : `0`)};
	padding: ${(props) => (props.isHover ? `8px` : `0`)};
	align-items: center;
	justify-content: flex-start;
	opacity: ${(props) => (props.isHover ? 1 : 0)};
	transition: all 150ms ease-in;
	cursor: ${(props) => (props.isHover ? `pointer` : `default`)};

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		width: ${(props) => (props.isHover ? `20px` : `0`)};
		padding: ${(props) => (props.isHover ? `4px` : `0`)};

		& > svg {
			font-size: ${(props) => props.theme.fontSize.large};
		}
	}
`;
