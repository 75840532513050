import { ActionTypes } from './activitySingle.types';

const INITIAL_STATE = {
	activityConfirmation: false,
	activity: null,
	experience: null,
	hasDropped: false,
};

const activitySingleReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.SET_ACTIVITY_SINGLE_MODAL:
			return {
				...state,
				activityConfirmation: action.payload,
			};

		case ActionTypes.SET_ACTIVITY_SINGLE_ID:
			return {
				...state,
				activity: action.payload,
			};

		case ActionTypes.SET_ACTIVITY_SINGLE_EXPERIENCE:
			return {
				...state,
				experience: action.payload,
			};

		case ActionTypes.SET_ACTIVITY_HAS_DROPPED:
			return {
				...state,
				hasDropped: action.payload,
			};

		default:
			return state;
	}
};

export default activitySingleReducer;
