import React, { useState } from 'react';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import { setDialogExperience } from '../../redux/dialogs/dialogs.actions';

/* FIREBASE */

import { firestore } from '../../utils/firebase';

import firebase from 'firebase';

/* Components */

import DialogExperienceContent from './DialogExperienceContent';

/* Styled Components */

import './DialogExperience.css';

import styled from 'styled-components';

function DialogExperience() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);

	const [currentEmployer, setCurrentEmployer] = useState(false);
	const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
	const [selectedDateUntil, setSelectedDateUntil] = useState(new Date());

	const [values, setValues] = useState({
		company: '',
		position: '',
		description: '',
	});

	const [errors, setErrors] = useState({
		date: null,
		company: null,
		position: null,
		description: null,
	});

	const [error, setError] = useState(null);
	const [errorSnackbar, setErrorSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleDateChangeFrom = (date) => {
		setSelectedDateFrom(date);
	};

	const handleDateChangeUntil = (date) => {
		setSelectedDateUntil(date);
	};

	const handleChange = (event) => {
		setValues((state) => {
			return { ...state, [event.target.id]: event.target.value };
		});
	};

	const handleCloseError = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setErrorSnackbar(false);
	};

	const handleCurrentEmployer = (event) => {
		setCurrentEmployer(event.target.checked);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		let errorCount = 0;

		setErrors({ date: '', company: '', position: '', description: '' });
		setError(null);
		setErrorSnackbar(false);

		if (selectedDateFrom === undefined || selectedDateUntil === undefined) {
			setErrors((state) => {
				return { ...state, date: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (values.company === '') {
			setErrors((state) => {
				return { ...state, company: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (values.position === '') {
			setErrors((state) => {
				return { ...state, position: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (values.description === '') {
			setErrors((state) => {
				return { ...state, description: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (selectedDateFrom > selectedDateUntil) {
			setErrors((state) => {
				return { ...state, date: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (errorCount > 0) {
			return false;
		}

		setIsLoading(true);

		const futureDate = new Date(2040, 12, 31);

		await firestore
			.collection('users')
			.doc(user.uid)
			.collection('experience')
			.doc()
			.set({
				dateFrom: firebase.firestore.Timestamp.fromDate(selectedDateFrom),
				dateUntil: currentEmployer
					? firebase.firestore.Timestamp.fromDate(futureDate)
					: firebase.firestore.Timestamp.fromDate(selectedDateUntil),
				company: values.company,
				position: values.position,
				description: values.description,
			})
			.then(setIsLoading(false))
			.then(dispatch(setDialogExperience(false)))
			.catch((error) => {
				setError(error.message);
				setErrors({ date: '', company: '', position: '', description: '' });
				setIsLoading(false);
				setErrorSnackbar(true);
			});
	};

	return (
		<DialogExperienceContent
			selectedDateFrom={selectedDateFrom}
			selectedDateUntil={selectedDateUntil}
			handleDateChangeFrom={handleDateChangeFrom}
			handleDateChangeUntil={handleDateChangeUntil}
			errors={errors}
			currentEmployer={currentEmployer}
			handleCurrentEmployer={handleCurrentEmployer}
			values={values}
			handleChange={handleChange}
			handleSubmit={handleSubmit}
			isLoading={isLoading}
			errorSnackbar={errorSnackbar}
			handleCloseError={handleCloseError}
			error={error}
		/>
	);
}

export default DialogExperience;
