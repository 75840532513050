import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import BusyBeeLogo from '../../assets/logo.svg';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import { changeAuthStage } from '../../redux/authStage/authStage.actions';
import { setShareModal } from '../../redux/dialogs/dialogs.actions';

/* FIREBASE */

import { auth } from '../../utils/firebase';

/* Material-UI */

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ShareIcon from '@material-ui/icons/Share';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

/* Styled Components */

import styled from 'styled-components';

function HideOnScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({ target: window ? window() : undefined });

	return (
		<Slide appear={false} direction='down' in={!trigger}>
			{children}
		</Slide>
	);
}

function Navigation(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useSelector((state) => state.user);

	const handleClick = (stage) => {
		dispatch(changeAuthStage(stage));
		history.push('/auth');
	};

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleNavigation = (target) => {
		history.push(target);
		setAnchorEl(null);
	};

	const handleSignOut = () => {
		auth.signOut();
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<HideOnScroll {...props}>
				<NavBar>
					<NavContent>
						<LogoLink to='/'>
							<Logo src={BusyBeeLogo} alt='Logo' />
							<LogoSmall src='/logo-icon.png' alt='Logo' />
						</LogoLink>
						{user ? (
							<ButtonContainer>
								<PublishIcon onClick={() => dispatch(setShareModal(true))} />
								{/*
								<MessagesContainer badgeContent={4}>
									<MessagesIcon />
								</MessagesContainer>*/}

								<ProfileAvatar alt='Profile Avatar' onClick={handleMenuClick}>
									{user.email.substring(0, 1)}
								</ProfileAvatar>
								<Dropdown
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleMenuClose}
								>
									<DropdownItem
										onClick={() => handleNavigation(`/profile/${user.uid}`)}
									>
										<AccountBoxIcon />
										Profiel
									</DropdownItem>
									<DropdownItem onClick={handleSignOut}>
										<ExitToAppIcon />
										Uitloggen
									</DropdownItem>
								</Dropdown>
							</ButtonContainer>
						) : (
							<ButtonContainer>
								<ButtonRegular onClick={() => handleClick('/signin')}>
									Inloggen
								</ButtonRegular>
								<ButtonSignUp onClick={() => handleClick('/signup')}>
									Aanmelden
								</ButtonSignUp>
							</ButtonContainer>
						)}
					</NavContent>
				</NavBar>
			</HideOnScroll>
			<Toolbar />
		</React.Fragment>
	);
}

export default Navigation;

const NavBar = styled(AppBar)`
	display: flex;
	align-items: center;
	background-color: ${(props) => props.theme.backgroundColor.secondary};
`;

const NavContent = styled(Toolbar)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: ${(props) => props.theme.innerWidth};
`;

const Logo = styled.img`
	cursor: pointer;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 30px;
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		display: none;
	}
`;

const LogoSmall = styled.img`
	display: none;
	max-height: 35px;
	cursor: pointer;
	padding-left: 30px;

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		display: inline;
	}
`;

const LogoLink = styled(Link)`
	width: 200px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-right: 30px;
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		padding-right: 20px;
	}
`;

const ButtonRegular = styled(Button)`
	font-size: 0.9rem;
	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		font-size: 0.8rem;
	}
`;

const ButtonSignUp = styled(Button)`
	margin: 0 0 0 20px;
	color: ${(props) => props.theme.backgroundColor.secondary};
	background-color: ${(props) => props.theme.backgroundColor.busybeeMain};
	font-size: 0.9rem;

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.busybeeMainHover};
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		font-size: 0.8rem;
	}
`;

const ProfileAvatar = styled(Avatar)`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 0 10px;
	background-color: ${(props) => props.theme.color.primary};
	cursor: pointer;

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin: 0 0 0 0;
	}
`;

const MessagesContainer = styled(Badge)`
	color: white;
	margin: 0 20px;
	cursor: pointer;

	& > span {
		background-color: ${(props) => props.theme.color.busybeeMain};
	}
`;

const MessagesIcon = styled(MailOutlineIcon)`
	color: ${(props) => props.theme.color.primary};
`;

const PublishIcon = styled(ShareIcon)`
	color: ${(props) => props.theme.color.primary};
	margin: 0 20px;
	cursor: pointer;

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin: 0 10px;
	}
`;

const Dropdown = styled(Menu)`
	& .MuiMenu-paper {
		border-radius: 10px;
	}
`;

const DropdownItem = styled(MenuItem)`
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.small};
	padding: 15px;

	& > svg {
		color: ${(props) => props.theme.color.primary};
		margin-right: 10px;
	}
`;
