import React from 'react';

/* Component Dependencies */

import TextfieldEditor from '../PageEditor/TextfieldEditor';
import ProfilePhoto from './ProfilePhoto';

/* Styled Components */

import styled from 'styled-components';

function Profile({ userId, userData, editToggle, editIconToggle, path }) {
	return (
		<Container>
			<ProfileInner>
				<ProfileInformation>
					<ProfileTitle>Profiel</ProfileTitle>
					<ProfileText
						subject='profileText'
						userId={userId}
						styleColor='contrastLight'
						styleFontSize='small'
						styleFontWeight='regular'
						styleBorderColor='contrastLight'
						styleBorderColorOffset='primary'
						styleBorderSize='2px'
						styleBorderRadius='10px'
						styleMargin='2px 0'
						styleLineHeight='2'
						userData={userData}
						editToggle={editToggle}
						editIconToggle={editIconToggle}
						path={path}
					/>
				</ProfileInformation>
				{/*
				<ProfilePhoto
					userId={userId}
					userData={userData}
					editToggle={editToggle}
					editIconToggle={editIconToggle}
					path={path}
/>*/}
			</ProfileInner>
		</Container>
	);
}

export default Profile;

const Container = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 340px;
	background-color: ${(props) => props.theme.backgroundColor.primary};

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		height: 10px;
		width: 100vw;
		overflow-x: hidden;
	}
`;

const ProfileInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: ${(props) => props.theme.innerWidth};
`;

const ProfileInformation = styled.div`
	display: flex;
	flex: 2;
	flex-direction: column;
	max-width: 600px;
	padding: 20px 40px 20px 0;
	height: 80%;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 20px;
	}

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		display: none;
	}
`;

const ProfileTitle = styled.h2`
	text-transform: uppercase;
	margin: 2px 0;
	padding-left: 5px;
	padding-bottom: 10px;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.large};
	color: ${(props) => props.theme.color.tertiary};
`;

const ProfileText = styled(TextfieldEditor)`
	margin: 2px 0;
	height: 100%;
`;
