import React, { useState, useEffect } from 'react';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import {
	updateProject,
	removeProject,
} from '../../redux/activities/activities.actions';
import { setActivityConfirmation } from '../../redux/activitySingle/activitySingle.actions';

/* FIREBASE */

import { firestore } from '../../utils/firebase';

/* Material-UI */

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

/* Component Dependencies */

import DialogActivityContent from '../Dialog/DialogActivityContent';

/* Styled Components */

import styled from 'styled-components';

const DeleteActivity = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const { activityConfirmation, activity, experience } = useSelector(
		(state) => state.activitySingle
	);
	const activities = useSelector((state) => state.activities);

	const project = useSelector(
		(state) => state.activities.filter((obj) => obj.id === activity)[0]
	);

	const [skills, setSkills] = useState([]);
	const [options, setOptions] = useState([]);

	const [values, setValues] = useState({
		activity: '',
		client: '',
		description: '',
	});

	const [errors, setErrors] = useState({
		activity: null,
		client: null,
		description: null,
	});

	const [error, setError] = useState(null);
	const [errorSnackbar, setErrorSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = (event) => {
		setValues((state) => {
			return { ...state, [event.target.id]: event.target.value };
		});
	};

	const handleChangeSelect = (event) => {
		setSkills(event);
	};

	const handleCloseError = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setErrorSnackbar(false);
	};

	const handleRemove = (id) => {
		firestore
			.collection('users')
			.doc(user.uid)
			.collection('experience')
			.doc(experience)
			.collection('projects')
			.doc(id)
			.delete()
			.then(dispatch(removeProject(id)))
			.then(dispatch(setActivityConfirmation(false)));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		let errorCount = 0;

		setErrors({
			activity: null,
			client: null,
			description: null,
			skills: null,
		});
		setError(null);
		setErrorSnackbar(false);

		if (values.company === '') {
			setErrors((state) => {
				return { ...state, activity: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}
		if (values.description === '') {
			setErrors((state) => {
				return { ...state, description: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (skills.length === 0) {
			setErrors((state) => {
				return { ...state, skills: 'Selecteer tenminste 1 expertise.' };
			});
			errorCount++;
		}

		if (errorCount > 0) {
			return false;
		}

		const skillsValuesOnly = [skills.map((skill) => skill.label)];
		const maxSortOrder = activities.reduce((acc, val) => {
			return acc > val.sortOrder ? acc : val.sortOrder;
		}, 0);

		setIsLoading(true);

		await firestore
			.collection('users')
			.doc(user.uid)
			.collection('experience')
			.doc(experience)
			.collection('projects')
			.doc(activity)
			.set(
				{
					activity: values.activity,
					client: values.client,
					description: values.description,
					sortOrder: activities.length === 0 ? 0 : maxSortOrder + 1,
					skills: skillsValuesOnly.join(','),
				},
				{ merge: true }
			)
			.then(
				dispatch(
					updateProject({
						id: activity,
						activity: values.activity,
						client: values.client,
						description: values.description,
						sortOrder: activities.length === 0 ? 0 : maxSortOrder + 1,
						skills: skillsValuesOnly.join(','),
					})
				)
			)
			.then(setIsLoading(false))
			.then(dispatch(setActivityConfirmation(false)))
			.catch((error) => {
				setError(error.message);
				setErrors({ activity: null, description: null, skills: null });
				setIsLoading(false);
				setErrorSnackbar(true);
			});
	};

	useEffect(() => {
		if (activities.length > 0 && activity) {
			const a = activities.filter((obj) => obj.id === activity)[0];

			if (a) {
				const s = a.skills
					.split(',')
					.reduce((acc, item) => [...acc, { value: item, label: item }], []);

				setValues({
					activity: a.activity,
					client: a.client,
					description: a.description,
				});
				setOptions(s);
				setSkills(s);
			}
		}
		return () => {
			setValues({
				activity: '',
				client: '',
				description: '',
			});
		};
	}, [activity, activities]);

	if (project) {
		return (
			<Container
				open={activityConfirmation}
				onClose={() => dispatch(setActivityConfirmation(false))}
			>
				<DialogTitle>{'Activiteit aanpassen'}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<DialogActivityContent
							handleChange={handleChange}
							handleSubmit={handleSubmit}
							handleChangeSelect={handleChangeSelect}
							values={values}
							options={options}
							skills={skills}
							errors={errors}
							isLoading={isLoading}
							activity={activity}
						/>
					</DialogContentText>
				</DialogContent>
				<Footer>
					<ButtonRemove onClick={() => handleRemove(activity)}>
						Verwijderen
					</ButtonRemove>
					<ButtonConfirm onClick={(event) => handleSubmit(event)}>
						Aanpassen
					</ButtonConfirm>
				</Footer>
			</Container>
		);
	} else {
		return null;
	}
};

export default DeleteActivity;

const Container = styled(Dialog)`
	& .MuiDialog-paper {
		border-radius: 20px;
		padding: 20px;

		@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
			padding: 0;
		}
	}
`;

const Footer = styled(DialogActions)`
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
`;

const ButtonConfirm = styled(Button)`
	color: ${(props) => props.theme.backgroundColor.secondary};
	background-color: ${(props) => props.theme.backgroundColor.busybeeMain};

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.busybeeMainHover};
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin: 0 10px 10px 10px;
	}
`;

const ButtonRemove = styled(Button)`
	color: ${(props) => props.theme.backgroundColor.secondary};
	background-color: ${(props) => props.theme.backgroundColor.secondaryFar};

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.secondaryFarHover};
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin: 0 10px 10px 10px;
	}
`;
