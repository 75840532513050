import React from 'react';

/* REDUX */

import { useDispatch } from 'react-redux';

import { addSkillCriteria } from '../../redux/skills/skills.actions';

/* Styled Components */

import styled from 'styled-components';

const ActivitySkillButton = styled.span`
	text-align: center;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.tiny};
	background-color: ${(props) => props.theme.buttonColor.primary};
	margin: 10px 20px 10px 0;
	padding: 5px 10px;
	border-radius: 3px;
	min-width: 50px;
	cursor: pointer;

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		font-size: ${(props) => props.theme.fontSize.timi};
		margin: 5px 10px 5px 0;
	}
`;

/* Code */

const SkillButton = ({ children }) => {
	const dispatch = useDispatch();

	const handleClick = (e) => {
		dispatch(addSkillCriteria(e.target.innerText));
	};

	return (
		<ActivitySkillButton onClick={handleClick}>{children}</ActivitySkillButton>
	);
};

export default SkillButton;
