import React from 'react';

import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

/* Material-UI */

import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

/* Styled Components */

import styled from 'styled-components';

const TextInput = (props) => {
	return <Input variant='outlined' {...props} />;
};

const ButtonPositive = ({ children, startIcon, onClick, disable }) => {
	if (disable) {
		return (
			<ButtonConfirm startIcon={startIcon} onClick={onClick} disabled>
				{children}
			</ButtonConfirm>
		);
	} else {
		return (
			<ButtonConfirm startIcon={startIcon} onClick={onClick}>
				{children}
			</ButtonConfirm>
		);
	}
};

const ButtonNegative = ({ children, startIcon, onClick, disable }) => {
	if (disable) {
		<ButtonRemove startIcon={startIcon} onClick={onClick} disabled>
			{children}
		</ButtonRemove>;
	} else {
		<ButtonRemove startIcon={startIcon} onClick={onClick}>
			{children}
		</ButtonRemove>;
	}
};

export { TextInput, ButtonPositive, ButtonNegative };

const Input = styled(TextField)`
	width: 100%;
	margin: 20px 0;
	color: ${(props) => props.theme.color.primary};
	line-height: 2px;

	& .MuiFormHelperText-root {
		color: red;
	}

	& .MuiInputBase-input {
		color: ${(props) => props.theme.color.primary};
		font-family: ${(props) => props.theme.fontFamily.primary};
		font-weight: ${(props) => props.theme.fontWeight.medium};
		font-size: ${(props) => props.theme.fontSize.medium};
		line-height: 1.35;

		@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
			font-size: ${(props) => props.theme.fontSize.small};
		}
	}

	& .MuiInputLabel-animated {
		font-family: ${(props) => props.theme.fontFamily.primary};
		font-weight: ${(props) => props.theme.fontWeight.bold};
		font-size: ${(props) => props.theme.fontSize.small};

		@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
			font-size: ${(props) => props.theme.fontSize.small};
		}
	}

	& > .MuiFormLabel-root.Mui-focused {
		color: ${(props) => props.theme.color.busybeeMain};
		font-family: ${(props) => props.theme.fontFamily.primary};
		font-weight: ${(props) => props.theme.fontWeight.bold};
		font-size: ${(props) => props.theme.fontSize.medium};

		@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
			font-size: ${(props) => props.theme.fontSize.small};
		}
	}

	& .MuiInput-underline:before {
		border-bottom: 1px solid ${(props) => props.theme.color.primary};
	}

	& .MuiInput-underline:after {
		border-bottom: 2px solid ${(props) => props.theme.color.busybeeMain};
	}

	& .MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: 1px solid ${(props) => props.theme.color.primary};
	}

	& > .MuiFormLabel-root.Mui-focused {
		color: ${(props) => props.theme.color.busybeeMain};
	}

	& > .MuiInputBase-root.Mui-focused > .MuiOutlinedInput-notchedOutline {
		border-color: ${(props) => props.theme.color.busybeeMain};
	}
`;

const ButtonConfirm = styled(Button)`
	color: ${(props) => props.theme.backgroundColor.secondary};
	background-color: ${(props) => props.theme.backgroundColor.busybeeMain};

	&.MuiButton-root.Mui-disabled {
		color: ${(props) => props.theme.buttonColor.primary};
		opacity: 0.9;
	}

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.busybeeMainHover};
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin: 0 10px 10px 10px;
	}
`;

const ButtonRemove = styled(Button)`
	color: ${(props) => props.theme.backgroundColor.secondary};
	background-color: ${(props) => props.theme.backgroundColor.secondaryFar};

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.secondaryFarHover};
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin: 0 10px 10px 10px;
	}
`;
