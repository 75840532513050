import React from 'react';

import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

/* Material-UI */

import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

/* Components */

import { TextInput } from '../Core/Form';

/* Styled Components */

import './DialogExperience.css';

import styled from 'styled-components';

const animatedComponents = makeAnimated();

const DialogActivityContent = ({
	handleChange,
	handleSubmit,
	handleChangeSelect,
	values,
	options,
	skills,
	errors,
	isLoading,
	activity = null,
}) => {
	return (
		<React.Fragment>
			<TextInput
				id='activity'
				label='Project omschrijving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
				value={values.activity}
				onChange={handleChange}
				helperText={errors.activity}
			/>
			<TextInput
				id='client'
				label='Opdrachtgever (optioneel)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
				value={values.client}
				onChange={handleChange}
				helperText={errors.client}
			/>
			<TextInput
				id='description'
				label='Samenvatting&nbsp;&nbsp;&nbsp;&nbsp;'
				value={values.description}
				onChange={handleChange}
				helperText={errors.description}
				multiline
				rows={6}
			/>
			<SkillSelect>
				<SelectText>Selecteer expertise(s)</SelectText>
				<SelectBar
					placeholder={'Expertise'}
					closeMenuOnSelect={false}
					components={animatedComponents}
					isMulti
					options={options}
					onChange={(event) => handleChangeSelect(event)}
					value={skills}
				/>
				{errors.skills && <FormHelperText>{errors.skills}</FormHelperText>}
			</SkillSelect>
			{!activity && (
				<ProcessContainer>
					<ButtonApply onClick={handleSubmit} type='submit'>
						Toevoegen
					</ButtonApply>
					{isLoading && <Spinner />}
				</ProcessContainer>
			)}
		</React.Fragment>
	);
};

export default DialogActivityContent;

const ProcessContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	height: 50px;
`;

const ButtonApply = styled(Button)`
	color: white;
	background-color: ${(props) => props.theme.backgroundColor.busybeeMain};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.tiny};

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.busybeeMainHover};
	}
`;

const Spinner = styled(CircularProgress)`
	color: ${(props) => props.theme.color.busybeeMain};
`;

const GuidingText = styled(Tooltip)``;

const SkillSelect = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	width: 100%;
	margin: 20px 0;
`;

const SelectText = styled.div`
	display: flex;
	justify-content: flex-start;
	color: rgba(0, 0, 0, 0.54);
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.small};

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		font-size: ${(props) => props.theme.fontSize.tiny};
	}
`;

const SelectBar = styled(CreatableSelect)`
	width: 100%;
	margin: 20px 0 0 0;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.small};

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		font-size: ${(props) => props.theme.fontSize.tiny};
	}

	&:focus {
		border-color: ${(props) => props.theme.backgroundColor.primary};
	}
`;

const FormHelperText = styled.div`
	color: red;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	letter-spacing: 0.03333em;
	font-weight: 400;
	line-height: 1.66;
	font-size: ${(props) => props.theme.fontSize.helper};
	margin-left: 14px;
	margin-top: 3px;
`;
