import React, { useState, useCallback, createRef, useRef } from 'react';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';

import { v4 as uuidv4 } from 'uuid';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import { setIconEditToggle } from '../../redux/editing/editing.actions';

/* FIREBASE */

import { firestore, storage } from '../../utils/firebase';

/* Material-UI */

import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slider from '@material-ui/core/Slider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';

import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

/* Styled Components */

import './ProfilePhoto.css';

import styled from 'styled-components';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function ProfilePhoto({ userId, userData, editToggle, editIconToggle, path }) {
	const dispatch = useDispatch();

	const avatarRef = useRef();

	const [open, setOpen] = useState(false);
	const [zoom, setZoom] = useState(1.2);
	const [file, setFile] = useState(null);
	const [progress, setProgress] = useState(0);
	const [error, setError] = useState(null);

	const user = useSelector((state) => state.user);

	const uploadFromBlobAsync = async ({ blobUrl, name }) => {
		if (!blobUrl || !name) return null;

		try {
			const blob = await fetch(blobUrl).then((r) => r.blob());

			const snapshot = await storage.ref().child(name).put(blob);

			return await snapshot.ref.getDownloadURL();
		} catch (error) {
			setError(error.message);
		}
	};

	const onDrop = useCallback(async (acceptedFiles) => {
		const file = acceptedFiles?.[0];

		if (!file) {
			return;
		}

		const customFilename = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'

		setFile(file);
		setProgress(1);
		setError(null);

		try {
			const res = await uploadFromBlobAsync({
				blobUrl: URL.createObjectURL(file),
				name: `${customFilename}`,
			});

			await firestore
				.collection('users')
				.doc(user)
				.set(
					{
						profilePhotoRaw: res,
						profilePhotoRawName: customFilename,
					},
					{ merge: true }
				)
				.catch((error) => {
					setError(error.message);
				});
		} catch (e) {
			setError(e.message);
		}

		setProgress(100);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: 'image/jpeg, image/png',
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = (event, newValue) => {
		setZoom(parseFloat(newValue));
	};

	const handleRemovePhoto = (event) => {
		if (!userData) {
			return false;
		}

		var file = null;

		if (userData.profilePhotoRaw) {
			file = userData.profilePhotoRawName;
		} else if (userData.profilePhoto) {
			file = userData.profilePhotoName;
		}

		var storageRef = storage.ref().child(file);

		storageRef
			.delete()
			.then(
				firestore.collection('users').doc(user).set(
					{
						profilePhotoRaw: null,
						profilePhotoRawName: null,
					},
					{ merge: true }
				)
			)
			.catch(function (error) {
				setError(error.message);
				firestore.collection('users').doc(user).set(
					{
						profilePhotoRaw: null,
						profilePhotoRawName: null,
					},
					{ merge: true }
				);
			});
	};

	const handleSubmitPhoto = async () => {
		const canvas = avatarRef.current.getImageScaledToCanvas().toDataURL();

		const customFilename = uuidv4();

		const r = await fetch(canvas);
		const blob = await r.blob();

		setFile(blob);
		setProgress(1);
		setError(null);

		try {
			const res = await uploadFromBlobAsync({
				blobUrl: URL.createObjectURL(blob),
				name: `${customFilename}`,
			});

			await firestore
				.collection('users')
				.doc(user)
				.set(
					{
						profilePhoto: res,
						profilePhotoName: customFilename,
					},
					{ merge: true }
				)
				.catch((error) => {
					setError(error.message);
				});
		} catch (e) {
			setError(e.message);
		}

		setProgress(100);
	};

	return (
		<React.Fragment>
			<Container>
				<ProfileImage
					onMouseOver={() =>
						user?.uid === userId &&
						dispatch(
							setIconEditToggle({ ...editIconToggle, profilePhoto: true })
						)
					}
					onMouseLeave={() =>
						user?.uid === userId &&
						dispatch(
							setIconEditToggle({ ...editIconToggle, profilePhoto: false })
						)
					}
					img={userData && userData.profilePhoto && userData.profilePhoto}
				>
					{userData && !userData.profilePhoto && <AccountCircleTwoToneIcon />}
				</ProfileImage>
				<TransitionGroup component={null}>
					{user?.uid === userId && editIconToggle.profilePhoto === true && (
						<CSSTransition classNames='profilePhoto' timeout={300}>
							<ActionContainer
								onMouseEnter={() =>
									dispatch(
										setIconEditToggle({ ...editIconToggle, profilePhoto: true })
									)
								}
								onMouseLeave={() =>
									dispatch(
										setIconEditToggle({
											...editIconToggle,
											profilePhoto: false,
										})
									)
								}
								onClick={handleClickOpen}
							>
								<AddPhoto />
								<AddPhotoText>Foto aanpassen</AddPhotoText>
							</ActionContainer>
						</CSSTransition>
					)}
				</TransitionGroup>
			</Container>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<NavBar>
					<NavContent>
						<DialogLeftContainer>
							<IconButton
								edge='start'
								color='inherit'
								onClick={handleClose}
								aria-label='close'
							>
								<CloseDialog />
							</IconButton>
							<DialogTitle>
								{(userData && userData.profilePhoto) ||
								(userData && userData.profilePhotoRaw)
									? 'Foto aanpassen'
									: 'Foto toevoegen'}
							</DialogTitle>
						</DialogLeftContainer>
					</NavContent>
				</NavBar>
				<Toolbar />
				{(userData && userData.profilePhoto) ||
				(userData && userData.profilePhotoRaw) ? (
					<EditorContainer>
						<AvatarEditor
							ref={avatarRef}
							image={
								userData.profilePhoto
									? `${userData.profilePhoto}`
									: `${userData.profilePhotoRaw}`
							}
							width={340}
							height={340}
							border={25}
							color={[0, 0, 0, 0.4]} // RGBA
							scale={zoom}
							rotate={0}
						/>
						<ZoomContainer>
							<ZoomText>Zoom {zoom}</ZoomText>
							<ZoomSlider
								defaultValue={parseFloat(zoom)}
								onChange={handleChange}
								step={0.1}
								min={0.6}
								max={2}
								aria-labelledby='zoom-slider'
							/>
						</ZoomContainer>
						<ButtonContainer>
							<ButtonApply onClick={handleSubmitPhoto}>Opslaan</ButtonApply>
							<ButtonRemove onClick={handleRemovePhoto}>
								Verwijderen
							</ButtonRemove>
						</ButtonContainer>
					</EditorContainer>
				) : (
					<React.Fragment>
						<DropzoneContainer {...getRootProps()}>
							<input {...getInputProps()} />
							{isDragActive ? <DropzoneAddIcon /> : <DropzoneAddIcon />}
						</DropzoneContainer>
						{error && error}
						{file && progress < 100 && <ProgressBar value={progress} />}
					</React.Fragment>
				)}
			</Dialog>
		</React.Fragment>
	);
}

export default ProfilePhoto;

const Container = styled.div`
	display: flex;
	position: relative;
	flex: 1;
	flex-direction: column;
	height: 100%;
	max-width: 340px;

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		display: none;
	}
`;

const ProfileImage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 340px;
	${(props) => props.img && `background-image: url(${props.img});`}
	background-size: cover;
	background-position: center;
	z-index: 1;

	& > svg {
		font-size: 10rem;
		color: white;
		opacity: 0.3;
		transition: opacity 150ms ease;
	}
`;

const ActionContainer = styled.div`
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	z-index: 2;
	cursor: pointer;
`;

const AddPhoto = styled(AddPhotoAlternateIcon)`
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	color: white;
	opacity: 0.9;
	font-size: 3em;
`;

const AddPhotoText = styled.p`
	position: relative;
	top: 50%;
	color: white;
	opacity: 0.8;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.huge};

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

const NavBar = styled(AppBar)`
	display: flex;
	align-items: center;
	background-color: ${(props) => props.theme.backgroundColor.secondaryClose};
`;

const NavContent = styled(Toolbar)`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const DialogLeftContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: 30px;
`;

const CloseDialog = styled(CloseIcon)`
	color: ${(props) => props.theme.backgroundColor.primary};
`;

const DialogTitle = styled.p`
	color: ${(props) => props.theme.backgroundColor.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.regular};
	font-size: ${(props) => props.theme.fontSize.huge};
	margin-left: 10px;
`;

const DropzoneContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 340px;
	max-height: 340px;
	width: 100%;
	height: 100%;
	min-width: 100px;
	justify-content: center;
	align-items: center;
	margin: auto;
	background-color: ${(props) => props.theme.backgroundColor.busybeeSecondary};
	border: 25px solid rgba(50, 67, 89, 0.1);
	border-radius: 15px;
	cursor: pointer;
`;

const DropzoneAddIcon = styled(AddPhotoAlternateIcon)`
	color: ${(props) => props.theme.color.primary};
	font-size: 5em;
	opacity: 0.5;
`;

const ProgressBar = styled(LinearProgress)`
	&.MuiLinearProgress-colorPrimary {
		background-color: white;
	}

	& > .MuiLinearProgress-barColorPrimary {
		background-color: ${(props) => props.theme.backgroundColor.busybeeMain};
	}
`;

const EditorContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	min-width: 100px;
	justify-content: center;
	align-items: center;
`;

const ZoomContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 390px;
	width: 100%;
	margin: 20px 0;
`;

const ZoomText = styled.div`
	color: ${(props) => props.theme.backgroundColor.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.small};
`;

const ZoomSlider = styled(Slider)`
	max-width: 300px;
	min-width: 100px;
	width: 100%;

	&.MuiSlider-root {
		color: ${(props) => props.theme.color.busybeeMain};
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 390px;
	width: 100%;
	margin: 20px 0;
`;

const ButtonRemove = styled(Button)`
	color: ${(props) => props.theme.color.primary};
	background-color: ${(props) =>
		props.theme.backgroundColor.busybeeMainSecondary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.tiny};

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.busybeeMainSecondary};
	}
`;

const ButtonApply = styled(Button)`
	color: white;
	background-color: ${(props) => props.theme.backgroundColor.busybeeMain};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.tiny};

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.busybeeMainHover};
	}
`;
