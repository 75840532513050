import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

/* DRAG 'N DROP */
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

/* ROUTING */

import { BrowserRouter } from 'react-router-dom';

/* DATA LAYER */

import { Provider } from 'react-redux';
import { store } from './redux/store';

/* STYLING */

import Theme from './utils/theme';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<Theme>
					<DndProvider backend={HTML5Backend}>
						<App />
					</DndProvider>
				</Theme>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
