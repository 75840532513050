import { combineReducers } from 'redux';

import skillReducer from './skills/skills.reducer';
import authStageReducer from './authStage/authStage.reducer';
import userReducer from './user/user.reducer';
import userDataReducer from './userData/userData.reducer';
import experienceReducer from './experience/experience.reducer';
import experienceSingleReducer from './experienceSingle/experienceSingle.reducer';
import activitiesReducer from './activities/activities.reducer';
import editingReducer from './editing/editing.reducer';
import dialogsReducer from './dialogs/dialogs.reducer';
import activitySingleReducer from './activitySingle/activitySingle.reducer';
import errorsReducer from './errors/errors.reducer';

const rootReducer = combineReducers({
	user: userReducer,
	userData: userDataReducer,
	experience: experienceReducer,
	activities: activitiesReducer,
	editing: editingReducer,
	dialogs: dialogsReducer,
	activitySingle: activitySingleReducer,
	experienceSingle: experienceSingleReducer,
	skillsSearch: skillReducer,
	authStage: authStageReducer,
	errors: errorsReducer,
});

export default rootReducer;
