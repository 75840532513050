import { EditingActionTypes } from './editing.types';

export const setIconEditToggle = (payload) => ({
	type: EditingActionTypes.SET_ICON_TOGGLE,
	payload: payload,
});

export const setEditToggle = (payload) => ({
	type: EditingActionTypes.SET_TOGGLE,
	payload: payload,
});
