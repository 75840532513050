import React, { useEffect, useState, memo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
	setExperienceConfirmation,
	setExperienceId,
	setExperienceActivityCount,
} from '../../redux/experienceSingle/experienceSingle.actions';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

/* Component Dependencies */

import ActivityProject from './Project';
import AddActivity from '../PageEditor/AddActivity';

import { TextRegular } from '../Core/Text';

/* FIREBASE */

import { firestore } from '../../utils/firebase';

/* Styled Components */
import EditIcon from '@material-ui/icons/Edit';

import styled from 'styled-components';

/* Code */

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const updateSortOrder = async (userId, experienceId, activityId, newOrder) => {
	firestore
		.collection('users')
		.doc(userId)
		.collection('experience')
		.doc(experienceId)
		.collection('projects')
		.doc(activityId)
		.set(
			{
				sortOrder: newOrder,
			},
			{ merge: true }
		)
		.then(console.log(`Updated to ${newOrder}`))
		.catch((error) => {
			console.log(error.message);
		});
};

const ProjectsList = memo(function ProjectsList({ projects, selectedSkills }) {
	return projects.map((p, index) => (
		<DecideOnProject
			p={p}
			index={index}
			selectedSkills={selectedSkills}
			key={p.id}
		/>
	));
});

const DecideOnProject = ({ p, index, selectedSkills }) => {
	const skillCount = selectedSkills.some((r) =>
		p.skills.split(',').includes(r)
	);

	if (skillCount > 0 || selectedSkills.length === 0) {
		return (
			<Draggable draggableId={p.id} index={index}>
				{(provided) => (
					<ActivityProject
						key={p.id}
						title={p.activity}
						client={p.client}
						description={p.description}
						skills={p.skills.split(',')}
						experience={p.experienceId}
						id={`${p.id}`}
						provided={provided}
						innerRef={provided.innerRef}
					/>
				)}
			</Draggable>
		);
	} else {
		return null;
	}
};

const Company = ({
	experience,
	activityCount,
	activities,
	company,
	position,
	description,
	userId,
}) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const selectedSkills = useSelector((state) => state.skillsSearch.skills);

	const [projects, setProjects] = useState([]);

	useEffect(() => {
		setProjects(
			activities.filter(
				(item, index, arr) =>
					arr.findIndex((obj) => obj.id === item.id) === index
			)
		);

		return () => {
			setProjects([]);
		};
	}, [activities, experience]);

	const handleClick = () => {
		if (user?.uid === userId) {
			dispatch(setExperienceId(experience));
			dispatch(setExperienceActivityCount(activityCount));
			dispatch(setExperienceConfirmation(true));
		}
	};

	const [isHover, setIsHover] = useState(false);

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(
			projects,
			result.source.index,
			result.destination.index
		);

		items.map((val, index) => {
			if (val.sortOrder !== index) {
				return updateSortOrder(user.uid, experience, val.id, index);
			}

			return null;
		});

		setProjects(items);
	};

	return (
		<ActivityContainer>
			<Activity
				onDoubleClick={() => user?.uid === userId && handleClick()}
				onMouseOver={() => user?.uid === userId && setIsHover(true)}
				onMouseLeave={() => user?.uid === userId && setIsHover(false)}
			>
				<ActivityInformation>
					<ActivityTitle>
						<Icon
							isHover={isHover}
							onClick={() => user?.uid === userId && handleClick()}
						>
							<EditIcon />
						</Icon>
						{company}
					</ActivityTitle>
					<ActivitySubtitle>{position}</ActivitySubtitle>
					<TextRegular
						padding={`10px 0 ${user?.uid === userId ? `20px` : `40px`} 0`}
					>
						{description}
					</TextRegular>
				</ActivityInformation>
			</Activity>
			{user?.uid === userId && <AddActivity experience={experience} />}
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='list'>
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<ProjectsList
								projects={projects}
								selectedSkills={selectedSkills}
							/>
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</ActivityContainer>
	);
};

export default Company;

const ActivityContainer = styled.div``;

const Activity = styled.div`
	display: flex;
	width: 100%;
`;

const ActivityInformation = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 40px;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 20px;
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		padding-left: 10px;
	}
`;

const ActivityTitle = styled.div`
	display: flex;
	align-items: center;
	height: 30px;
	margin: 0 0;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.large};
	padding-bottom: 5px;

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		font-size: ${(props) => props.theme.fontSize.medium};
	}
`;

const ActivitySubtitle = styled.h3`
	margin: 0 0;
	//color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.small};

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		font-size: ${(props) => props.theme.fontSize.tiny};
	}
`;

const Projects = styled.div``;

const Icon = styled.div`
	display: flex;
	height: 100%;
	width: ${(props) => (props.isHover ? `30px` : `0`)};
	padding: ${(props) => (props.isHover ? `8px` : `0`)};
	align-items: center;
	justify-content: flex-start;
	opacity: ${(props) => (props.isHover ? 1 : 0)};
	transition: all 150ms ease-in;
	cursor: ${(props) => (props.isHover ? `pointer` : `default`)};

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		width: ${(props) => (props.isHover ? `20px` : `0`)};
		padding: ${(props) => (props.isHover ? `4px` : `0`)};

		& > svg {
			font-size: ${(props) => props.theme.fontSize.large};
		}
	}
`;
