import React, { useState } from 'react';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import { setDialogExperience } from '../../redux/dialogs/dialogs.actions';

/* FIREBASE */

import { firestore } from '../../utils/firebase';

import firebase from 'firebase';

/* Material-UI */

import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';

/* Components */

import { TextTitle } from '../Core/Text';
import { TextInput } from '../Core/Form';

/* Styled Components */

import './DialogExperience.css';

import styled from 'styled-components';

function DialogExperienceContent({
	selectedDateFrom,
	selectedDateUntil,
	handleDateChangeFrom,
	handleDateChangeUntil,
	errors,
	currentEmployer,
	handleCurrentEmployer,
	values,
	handleChange,
	handleSubmit,
	isLoading,
	errorSnackbar,
	handleCloseError,
	error,
	experience = null,
}) {
	return (
		<form>
			<Wrapper standalone={!experience ? true : false}>
				<Container>
					{!experience && (
						<TextTitle padding='10px 0 20px 0'>
							Opdrachtgever toevoegen
						</TextTitle>
					)}
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DateContainer>
							<DatePicker
								disableToolbar
								variant='inline'
								format='dd/MM/yyyy'
								margin='normal'
								id='date-picker-inline'
								label='Datum ingang'
								value={selectedDateFrom}
								onChange={handleDateChangeFrom}
								helperText={errors.date}
								KeyboardButtonProps={{
									'aria-label': 'change start date',
								}}
							/>
							<DatePicker
								disableToolbar
								variant='inline'
								format='dd/MM/yyyy'
								margin='normal'
								id='date-picker-inline'
								label='Datum einde'
								value={selectedDateUntil}
								onChange={handleDateChangeUntil}
								disabled={currentEmployer ? true : false}
								KeyboardButtonProps={{
									'aria-label': 'change end date',
								}}
							/>
						</DateContainer>
					</MuiPickersUtilsProvider>
					<PresentEmployer
						control={
							<Checkbox
								checked={currentEmployer}
								onChange={handleCurrentEmployer}
							/>
						}
						label='Ik werk hier nog'
					/>
					<TextInput
						id='company'
						label='Organisatie&nbsp;&nbsp;'
						value={values.company}
						onChange={handleChange}
						helperText={errors.company}
					/>
					<TextInput
						id='position'
						label='Functieomschrijving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
						value={values.position}
						onChange={handleChange}
						helperText={errors.position}
					/>
					<TextInput
						id='description'
						label='Samenvatting&nbsp;'
						value={values.description}
						onChange={handleChange}
						helperText={errors.description}
						multiline
						rows={7}
					/>
					{!experience && (
						<ProcessContainer>
							<ButtonApply onClick={handleSubmit} type='submit'>
								Toevoegen
							</ButtonApply>
							{isLoading && <Spinner />}
						</ProcessContainer>
					)}
				</Container>
			</Wrapper>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={errorSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseError}
				message={error}
				action={
					<React.Fragment>
						<IconButton
							size='small'
							aria-label='close'
							color='inherit'
							onClick={handleCloseError}
						>
							<CloseIcon fontSize='small' />
						</IconButton>
					</React.Fragment>
				}
			/>
		</form>
	);
}

export default DialogExperienceContent;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-top: ${(props) => (props.standalone ? `120px` : `0`)};

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		padding: 20px;
		margin-top: ${(props) => (props.standalone ? `80px` : `0`)};
		width: calc(100% - 40px);
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 500px;
	align-items: center;

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		max-width: 400px;
	}
`;

const DateContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		flex-direction: column;
		margin-top: 40px;
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin-top: 0;
	}
`;

const DatePicker = styled(KeyboardDatePicker)`
	& .MuiFormHelperText-root {
		color: red;
	}

	& .MuiInputLabel-animated {
		font-family: ${(props) => props.theme.fontFamily.primary};
		font-weight: ${(props) => props.theme.fontWeight.medium};
		font-size: ${(props) => props.theme.fontSize.small};
	}
	& .MuiInputBase-input {
		font-family: ${(props) => props.theme.fontFamily.primary};
		font-weight: ${(props) => props.theme.fontWeight.medium};
		font-size: ${(props) => props.theme.fontSize.small};
		color: ${(props) =>
			props.disabled ? props.theme.color.tertiary : props.theme.color.primary};
	}

	& > .MuiFormLabel-root.Mui-focused {
		color: ${(props) => props.theme.color.busybeeMain};
	}

	& .MuiInput-underline:before {
		border-bottom: 1px solid
			${(props) =>
				props.disabled
					? props.theme.color.tertiary
					: props.theme.color.primary};
	}

	& .MuiInput-underline:after {
		border-bottom: 2px solid ${(props) => props.theme.color.busybeeMain};
	}

	& .MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: 1px solid ${(props) => props.theme.color.primary};
	}

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		margin-bottom: 30px;
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		flex-direction: column;
		margin-top: 10px;
	}
`;

const ProcessContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	height: 50px;
`;

const ButtonApply = styled(Button)`
	color: white;
	background-color: ${(props) => props.theme.backgroundColor.busybeeMain};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.tiny};

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.busybeeMainHover};
	}
`;

const Spinner = styled(CircularProgress)`
	color: ${(props) => props.theme.color.busybeeMain};
`;

const Title = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-start;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.huge};
	margin-bottom: 20px;
`;

const PresentEmployer = styled(FormControlLabel)`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-start;

	& > span {
		color: ${(props) => props.theme.color.primary};
		font-family: ${(props) => props.theme.fontFamily.primary};
		font-weight: ${(props) => props.theme.fontWeight.medium};
		font-size: ${(props) => props.theme.fontSize.small};
	}

	& .MuiCheckbox-colorSecondary.Mui-checked {
		color: ${(props) => props.theme.color.primary};
	}
`;
