import React, { useEffect, useState } from 'react';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';
import { removeExperience } from '../../redux/experience/experience.actions';
import { setExperienceConfirmation } from '../../redux/experienceSingle/experienceSingle.actions';

/* FIREBASE */

import { firestore } from '../../utils/firebase';
import firebase from 'firebase';

/* Material-UI */

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

/* Component Dependencies */

import DialogExperienceContent from '../Dialog/DialogExperienceContent';

/* Styled Components */

import styled from 'styled-components';

const DeleteExperience = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const { experience, experienceConfirmation, activityCount } = useSelector(
		(state) => state.experienceSingle
	);
	const project = useSelector((state) => state.experience);

	const [currentEmployer, setCurrentEmployer] = useState(false);
	const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
	const [selectedDateUntil, setSelectedDateUntil] = useState(new Date());

	const [values, setValues] = useState({
		company: '',
		position: '',
		description: '',
	});

	const [errors, setErrors] = useState({
		date: null,
		company: null,
		position: null,
		description: null,
	});

	const [error, setError] = useState(null);
	const [errorSnackbar, setErrorSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleDateChangeFrom = (date) => {
		setSelectedDateFrom(date);
	};

	const handleDateChangeUntil = (date) => {
		setSelectedDateUntil(date);
	};

	const handleChange = (event) => {
		setValues((state) => {
			return { ...state, [event.target.id]: event.target.value };
		});
	};

	const handleCloseError = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setErrorSnackbar(false);
	};

	const handleCurrentEmployer = (event) => {
		setCurrentEmployer(event.target.checked);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		let errorCount = 0;

		setErrors({ date: '', company: '', position: '', description: '' });
		setError(null);
		setErrorSnackbar(false);

		if (selectedDateFrom === undefined || selectedDateUntil === undefined) {
			setErrors((state) => {
				return { ...state, date: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (values.company === '') {
			setErrors((state) => {
				return { ...state, company: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (values.position === '') {
			setErrors((state) => {
				return { ...state, position: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (values.description === '') {
			setErrors((state) => {
				return { ...state, description: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (selectedDateFrom > selectedDateUntil) {
			setErrors((state) => {
				return { ...state, date: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (errorCount > 0) {
			return false;
		}

		setIsLoading(true);

		const futureDate = new Date(2040, 12, 31);

		await firestore
			.collection('users')
			.doc(user.uid)
			.collection('experience')
			.doc(experience)
			.set(
				{
					dateFrom: firebase.firestore.Timestamp.fromDate(selectedDateFrom),
					dateUntil: currentEmployer
						? firebase.firestore.Timestamp.fromDate(futureDate)
						: firebase.firestore.Timestamp.fromDate(selectedDateUntil),
					company: values.company,
					position: values.position,
					description: values.description,
				},
				{ merge: true }
			)
			.then(setIsLoading(false))
			.then(dispatch(setExperienceConfirmation(false)))
			.catch((error) => {
				setError(error.message);
				setErrors({ date: '', company: '', position: '', description: '' });
				setIsLoading(false);
				setErrorSnackbar(true);
			});
	};

	const handleRemove = () => {
		firestore
			.collection('users')
			.doc(user.uid)
			.collection('experience')
			.doc(experience)
			.delete()
			.then(dispatch(removeExperience(experience)))
			.then(dispatch(setExperienceConfirmation(false)));
	};

	useEffect(() => {
		if (experience && project[experience]) {
			const p = project[experience];

			const dateFrom = p.dateFrom;
			const dateUntil = p.dateUntil;

			const c = dateUntil.toDate().getYear() + 1900 === 2041 ? true : false;
			const today = new Date();

			setValues({
				company: p.company,
				position: p.position,
				description: p.description,
			});

			setCurrentEmployer(c);

			setSelectedDateFrom(dateFrom.toDate());
			setSelectedDateUntil(c ? today : dateUntil.toDate());
		}
		return () => {
			setValues({
				company: '',
				position: '',
				description: '',
			});
		};
	}, [project, experience]);

	if (experience && project[experience]) {
		return (
			<Container
				open={experienceConfirmation}
				onClose={() => dispatch(setExperienceConfirmation(false))}
			>
				<DialogTitle>{'Ervaring aanpassen'}</DialogTitle>
				<DialogContent>
					<DialogExperienceContent
						selectedDateFrom={selectedDateFrom}
						selectedDateUntil={selectedDateUntil}
						handleDateChangeFrom={handleDateChangeFrom}
						handleDateChangeUntil={handleDateChangeUntil}
						errors={errors}
						currentEmployer={currentEmployer}
						handleCurrentEmployer={handleCurrentEmployer}
						values={values}
						handleChange={handleChange}
						handleSubmit={handleSubmit}
						isLoading={isLoading}
						errorSnackbar={errorSnackbar}
						handleCloseError={handleCloseError}
						error={error}
						experience={experience}
					/>
				</DialogContent>
				<Footer>
					<div>
						{activityCount === 0 && (
							<ButtonRemove onClick={() => handleRemove()}>
								Verwijderen
							</ButtonRemove>
						)}
					</div>
					<ButtonConfirm onClick={(e) => handleSubmit(e)}>
						Aanpassen
					</ButtonConfirm>
				</Footer>
			</Container>
		);
	} else {
		return null;
	}
};

export default DeleteExperience;

const Container = styled(Dialog)`
	& .MuiDialog-paper {
		border-radius: 20px;
		padding: 20px;

		@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
			padding: 0;
		}
	}
`;

const Footer = styled(DialogActions)`
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
`;

const ButtonConfirm = styled(Button)`
	color: ${(props) => props.theme.backgroundColor.secondary};
	background-color: ${(props) => props.theme.backgroundColor.busybeeMain};

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.busybeeMainHover};
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin: 0 10px 10px 10px;
	}
`;

const ButtonRemove = styled(Button)`
	color: ${(props) => props.theme.backgroundColor.secondary};
	background-color: ${(props) => props.theme.backgroundColor.secondaryFar};

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.secondaryFarHover};
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin: 0 10px 10px 10px;
	}
`;
