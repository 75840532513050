import React, { useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';

import BusyBeeLogo from '../../assets/logo.svg';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import { changeAuthStage } from '../../redux/authStage/authStage.actions';
import { setUser } from '../../redux/user/user.actions';

/* FIREBASE */
import firebase from 'firebase/app';
import { auth, firestore } from '../../utils/firebase';

/* Material-UI */

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';

/* Styled Components */

import styled from 'styled-components';

function SignIn() {
	const dispatch = useDispatch();
	const history = useHistory();

	const [values, setValues] = useState({
		email: '',
		password: '',
		showPassword: false,
	});

	const userDataLocal = useSelector((state) => state.user);

	useEffect(() => {
		userDataLocal && history.push('/profile/' + userDataLocal.uid);
		return () => {};
	}, [userDataLocal]);

	const [errors, setErrors] = useState({ email: '', password: '' });

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const getUserData = ({ user }) => {
		firestore
			.collection('users')
			.doc(user.uid)
			.get()
			.then(function (doc) {
				if (doc.exists) {
					dispatch(setUser(user));
					return true;
				} else {
					return false;
				}
			})
			.catch(function (error) {
				return false;
			});
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		let errors = 0;

		if (values.password === '') {
			setErrors({ ...errors, password: 'Vul een geldige waarde in.' });
			errors++;
		}

		if (values.email === '') {
			setErrors({ ...errors, email: 'Vul een geldige waarde in.' });
			errors++;
		}

		if (errors === 0) {
			auth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
				return auth
					.signInWithEmailAndPassword(values.email, values.password)
					.then((user) => getUserData(user))
					.catch((error) => {
						console.log('Something went wrong..');
					});
			});
		}
	};

	return (
		<form>
			<Container>
				<Logo src={BusyBeeLogo} alt='Logo' />
				<InputText variant='outlined'>
					<InputLabel htmlFor='email'>Emailadres</InputLabel>
					{errors.email !== '' ? (
						<OutlinedInput
							error
							name='email'
							id='email'
							value={values.email}
							onChange={handleChange('email')}
							startAdornment={
								<InputAdornment position='start'>
									<AccountCircle />
								</InputAdornment>
							}
							labelWidth={86}
						/>
					) : (
						<OutlinedInput
							id='input-with-icon-adornment'
							value={values.email}
							onChange={handleChange('email')}
							startAdornment={
								<InputAdornment position='start'>
									<AccountCircle />
								</InputAdornment>
							}
							labelWidth={86}
						/>
					)}

					<ErrorMessage id='component-error-text'>
						{errors.email !== '' && errors.email}
					</ErrorMessage>
				</InputText>
				<InputText variant='outlined'>
					<InputLabel htmlFor='password'>Wachtwoord</InputLabel>
					<OutlinedInput
						name='password'
						id='password'
						type={values.showPassword ? 'text' : 'password'}
						value={values.password}
						onChange={handleChange('password')}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge='end'
								>
									{values.showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
						labelWidth={92}
					/>
				</InputText>
				<ButtonSignUp type='submit' onClick={handleSubmit}>
					Inloggen
				</ButtonSignUp>
				<ButtonNotSignedUp onClick={() => dispatch(changeAuthStage('/signup'))}>
					Ik heb nog geen account.
				</ButtonNotSignedUp>
			</Container>
		</form>
	);
}

export default SignIn;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: calc(100vh - 100px);
	max-width: ${(props) => props.theme.innerWidth};
	align-items: center;
	justify-content: center;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		width: calc(100% - 20px);
	}
`;

const Logo = styled.img`
	max-width: 300px;
	margin-bottom: 30px;
`;

const InputText = styled(FormControl)`
	width: 300px;
	margin: 10px 0;

	& > .MuiFormLabel-root.Mui-focused {
		color: ${(props) => props.theme.color.busybeeMain};
	}

	& > .MuiInputBase-root.Mui-focused > .MuiOutlinedInput-notchedOutline {
		border-color: ${(props) => props.theme.color.busybeeMain};
	}
`;

const ErrorMessage = styled(FormHelperText)`
	color: red;
`;

const ButtonSignUp = styled(Button)`
	width: 300px;
	margin: 10px 0;
	height: 50px;
	color: ${(props) => props.theme.backgroundColor.secondary};
	background-color: ${(props) => props.theme.backgroundColor.busybeeMain};

	&:hover {
		background-color: ${(props) =>
			props.theme.backgroundColor.busybeeMainHover};
	}
`;

const ButtonNotSignedUp = styled.div`
	color: ${(props) => props.theme.color.busybeeMain};
	margin-top: 10px;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		color: ${(props) => props.theme.color.busybeeMainHover};
	}
`;
