import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import FlipMove from 'react-flip-move';

import { useParams } from 'react-router-dom';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import { addExperience } from '../redux/experience/experience.actions';

import { addProject } from '../redux/activities/activities.actions';

import { setUserData } from '../redux/userData/userData.actions';

/* FIREBASE */

import { firestore } from '../utils/firebase';

/* Material-UI */

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

/* Component Dependencies */

import PageContainer from '../components/Containers/PageContainer';
import Header from '../components/Header/Header';
import Profile from '../components/Profile/Profile';
import Company from '../components/Activity/Company';
import AddExperience from '../components/PageEditor/AddExperience';
import Skills from '../components/Skills/Skills';

import DeleteActivity from '../components/Modals/DeleteActivity';
import DeleteExperience from '../components/Modals/DeleteExperience';
import Share from '../components/Share/Share';

/* Styled Components */

import styled from 'styled-components';

/* Code */

function ResumePage() {
	const dispatch = useDispatch();
	const history = useHistory();

	const { userId } = useParams();

	const user = useSelector((state) => state.user);
	const userData = useSelector((state) => state.userData);
	const experiences = useSelector((state) => state.experience);
	const activities = useSelector((state) => state.activities);
	const selectedSkills = useSelector((state) => state.skillsSearch.skills);
	const editToggle = useSelector((state) => state.editing.edit);
	const editIconToggle = useSelector((state) => state.editing.icon);

	const [skills, setSkills] = useState([]);

	useEffect(() => {
		var unsubscribeExperience = firestore
			.collection('users')
			.doc(userId)
			.collection('experience')
			.orderBy('dateUntil', 'desc')
			.onSnapshot(function (querySnapshot) {
				querySnapshot.forEach(function (doc) {
					firestore
						.collection('users')
						.doc(userId)
						.collection('experience')
						.doc(doc.id)
						.collection('projects')
						.orderBy('sortOrder', 'asc')
						.onSnapshot(function (projectSnapshot) {
							projectSnapshot.forEach(function (activity) {
								dispatch(
									addProject({
										experienceId: doc.id,
										id: activity.id,
										...activity.data(),
									})
								);
							});
						});

					dispatch(addExperience([doc.id, doc.data()]));
				});
			});

		var unsubscribeUserData = firestore
			.collection('users')
			.doc(userId)
			.onSnapshot(
				function (doc) {
					if (doc.exists) {
						dispatch(setUserData(doc.data()));
					} else {
						history.push('/auth');
					}
				},
				function (error) {
					console.log(error.message);
				}
			);
		return () => {
			unsubscribeExperience();
			unsubscribeUserData();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let s = [];

		activities.map((obj) =>
			obj.skills.split(',').map((skill) => s.push(skill))
		);

		setSkills(
			s
				.filter((v, i, a) => a.indexOf(v) === i)
				.reduce((a, v) => [...a, { value: v, label: v }], [])
				.filter((v, i, a) => a.indexOf(v) === i)
		);

		return () => {
			setSkills([]);
		};
	}, [activities]);

	const MonthsFormat = [
		'jan',
		'feb',
		'mar',
		'apr',
		'mei',
		'jun',
		'jul',
		'aug',
		'sep',
		'okt',
		'nov',
		'dec',
	];

	const [filter, setFilter] = useState([]);

	useEffect(() => {
		setFilter(
			selectedSkills.map((skill) => ({
				value: skill,
				label: skill,
			}))
		);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSkills]);

	const decideOnExperience = (key, value) => {
		const skillCount = activities
			.filter((obj) => obj.experienceId === key)
			.reduce((acc, val) => {
				const skills = val.skills.split(',');
				selectedSkills.some((r) => skills.includes(r));
				return selectedSkills.length;
			}, 0);

		if (
			skillCount > 0 ||
			(selectedSkills.length === 0 &&
				activities.filter((obj) => obj.experienceId === key).length > 0) ||
			user?.uid === userId
		) {
			return (
				<TimelineItem key={'exp-' + key}>
					<TimelinePeriod>
						{MonthsFormat[new Date(parseInt(value.dateFrom * 1000)).getMonth()]}{' '}
						{value.dateFrom.toDate().getYear() + 1900} -{' '}
						{value.dateUntil.toDate().getYear() + 1900 !== 2041 &&
							MonthsFormat[
								new Date(parseInt(value.dateUntil * 1000)).getMonth()
							]}{' '}
						{value.dateUntil.toDate().getYear() + 1900 === 2041
							? `Huidig`
							: value.dateUntil.toDate().getYear() + 1900}
					</TimelinePeriod>
					<TimelinePath>
						<TimelineMarker></TimelineMarker>
						<TimelineConnector />
					</TimelinePath>
					<TimelineActivity>
						<Company
							key={key}
							experience={key}
							activityCount={
								activities.filter((obj) => obj.experienceId === key).length
							}
							activities={activities.filter((obj) => obj.experienceId === key)}
							company={value.company}
							position={value.position}
							description={value.description}
							userId={userId}
						/>
					</TimelineActivity>
				</TimelineItem>
			);
		} else {
			return null;
		}
	};

	return (
		<PageContainer>
			<Header userId={userId} path={history.pathname} />
			<Profile userId={userId} path={history.pathname} />
			<Skills placeholder={'Expertise'} margin='40px 0' />
			<Experience align='left'>
				<FlipMove>
					{experiences ? (
						Object.entries(experiences).map(([key, value]) =>
							decideOnExperience(key, value)
						)
					) : (
						<div></div>
					)}
				</FlipMove>
			</Experience>
			{user?.uid === userId && <AddExperience />}
			{user?.uid === userId && <DeleteActivity />}
			{user?.uid === userId && <DeleteExperience />}
			{user?.uid === userId && <Share />}
		</PageContainer>
	);
}

export default ResumePage;

const Experience = styled(Timeline)`
	display: flex;
	align-items: left;
	width: 100%;
	max-width: ${(props) => props.theme.innerWidth};
`;

const TimelinePeriod = styled(TimelineOppositeContent)`
	&.MuiTimelineOppositeContent-root {
		display: flex;
		flex: 0;
		min-width: 150px;
		align-items: left;
		padding-left: 0;
		text-transform: uppercase;
		font-family: ${(props) => props.theme.fontFamily.primary};
		font-weight: ${(props) => props.theme.fontWeight.semibold};
		font-size: ${(props) => props.theme.fontSize.tiny};

		@media (max-width: ${(props) => props.theme.breakpoint.md}) {
			display: none;
		}

		@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
			padding-left: 30px;
		}
	}
`;

const TimelineMarker = styled(TimelineDot)`
	&.MuiTimelineDot-defaultGrey {
		background-color: ${(props) => props.theme.backgroundColor.primary};
	}
`;

const TimelinePath = styled(TimelineSeparator)`
	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		margin-left: 35px;
	}

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin-left: 25px;
	}
`;

const TimelineActivity = styled(TimelineContent)`
	&.MuiTimelineContent-root {
		padding: 0px 16px;
	}
`;
