import React, { useEffect, useState } from 'react';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import { setShareModal } from '../../redux/dialogs/dialogs.actions';

/* FIREBASE */

import { firestore, functions } from '../../utils/firebase';
import firebase from 'firebase';

/* Material-UI */

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import CircularProgress from '@material-ui/core/CircularProgress';

/* Components */

import Skills from '../Skills/Skills';

import { TextInput, ButtonPositive } from '../Core/Form';
import { TextRegular } from '../Core/Text';

/* Styled Components */

import styled from 'styled-components';

const Share = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const userData = useSelector((state) => state.userData);
	const shareOpen = useSelector((state) => state.dialogs.share);

	const [values, setValues] = useState({ email: '' });
	const [errors, setErrors] = useState({ email: null });
	const [loading, setLoading] = useState(false);
	const [finalize, setFinalize] = useState(false);

	const handleChange = (event) => {
		setValues((state) => {
			return { ...state, [event.target.id]: event.target.value };
		});
	};

	const handleFinalize = (status) => {
		setLoading(false);
		setFinalize(true);
	};

	const handleSubmit = async (receiver, displayName, profileUrl) => {
		let errorCount = 0;

		setErrors({ email: null });

		let re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!re.test(values.email)) {
			setErrors((state) => {
				return { ...state, email: 'Voer een geldig emailadres in.' };
			});
			errorCount++;
		}

		if (errorCount > 0) {
			return false;
		}

		setLoading(true);

		const callable = functions.httpsCallable('sharePersonal');
		return callable({
			receiver: receiver,
			profileName: displayName,
			profileUrl: `https://busypeople.io/public/${profileUrl}`,
			subject: `${displayName} wil zijn profiel met je delen.`,
		}).then((obj) => handleFinalize(obj.data.status));
	};

	useEffect(() => {
		setLoading(false);
		setFinalize(false);
	}, []);

	return (
		<Container open={shareOpen} onClose={() => dispatch(setShareModal(false))}>
			<Header>
				<PublishIcon />
				Profiel Delen
			</Header>
			<Content>
				<TextRegular margin='5px 5px 0 5px'>
					Voer een emailadres in om jouw profiel mee te delen.
				</TextRegular>
				<TextInput
					id='email'
					label='Emailadres&nbsp;&nbsp;&nbsp;'
					value={values.email}
					onChange={handleChange}
					helperText={errors.email}
				/>
				<TextRegular margin='5px 5px 20px 5px'>
					Het profiel wordt getoond inclusief geselecteerde filters. Selecteer
					geen filters om je volledige profiel te delen.
				</TextRegular>
				<Skills
					placeholder={'Selecteer filters'}
					margin='10px 0 20px 0'
					contained={true}
				/>
			</Content>
			<Footer>
				<div></div>
				<ButtonPositive
					startIcon={loading && <Spinner style={{ width: 20, height: 20 }} />}
					onClick={() =>
						handleSubmit(
							values.email,
							userData.displayName,
							user.uid,
							setLoading,
							handleFinalize
						)
					}
					disable={finalize}
				>
					Delen
				</ButtonPositive>
			</Footer>
		</Container>
	);
};

export default Share;

const Container = styled(Dialog)`
	& .MuiDialog-paper {
		border-radius: 20px;
		padding: 18px;
		height: 690px;
		max-height: calc(100vh - 100px);

		@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
			padding: 8px 4px;
		}
	}
`;

const Content = styled(DialogContent)`
	max-width: 300px;
`;

const Header = styled(DialogTitle)`
	& > h2 {
		display: flex;
		align-items: center;
	}
`;

const Footer = styled(DialogActions)`
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
`;

const PublishIcon = styled(ShareIcon)`
	color: ${(props) => props.theme.color.primary};
	margin-right: 20px;

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		margin: 0 10px;
	}
`;

const Spinner = styled(CircularProgress)`
	color: ${(props) => props.theme.color.contrastLight};
	margin-left: 5px;
`;
