import React from 'react';

/* Styled Components */

import styled from 'styled-components';

const TextTitle = ({ children, padding, margin, props }) => {
	return (
		<TextTitleStyle padding={padding} margin={margin} {...props}>
			{children}
		</TextTitleStyle>
	);
};

const TextSubtitle = ({ children, padding, margin, props }) => {
	return (
		<TextSubtitleStyle padding={padding} margin={margin} {...props}>
			{children}
		</TextSubtitleStyle>
	);
};

const TextRegular = ({ children, padding, margin, props }) => {
	return (
		<TextRegularStyle padding={padding} margin={margin} {...props}>
			{children}
		</TextRegularStyle>
	);
};

export { TextRegular, TextTitle, TextSubtitle };

const TextTitleStyle = styled.div`
	padding: ${(props) => (props.padding ? props.padding : `5px`)};
	margin: ${(props) => (props.margin ? props.margin : `0`)};
	display: flex;
	width: 100%;
	justify-content: flex-start;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.huge};

	@media (max-width: ${(props) => props.theme.breakpoint.xs}) {
		font-size: ${(props) => props.theme.fontSize.large};
	}
`;

const TextSubtitleStyle = styled.div`
	padding: ${(props) => (props.padding ? props.padding : `5px`)};
	margin: ${(props) => (props.margin ? props.margin : `0`)};
	display: flex;
	width: 100%;
	justify-content: flex-start;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.large};

	@media (max-width: ${(props) => props.theme.breakpoint.xs}) {
		font-size: ${(props) => props.theme.fontSize.medium};
	}
`;

const TextRegularStyle = styled.div`
	padding: ${(props) => (props.padding ? props.padding : `5px`)};
	margin: ${(props) => (props.margin ? props.margin : `0`)};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.regular};
	font-size: ${(props) => props.theme.fontSize.small};
	line-height: 2;

	@media (max-width: ${(props) => props.theme.breakpoint.xs}) {
		font-size: ${(props) => props.theme.fontSize.tiny};
	}
`;
