import React, { useState, useEffect } from 'react';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import {
	setIconEditToggle,
	setEditToggle,
} from '../../redux/editing/editing.actions';

import { addError } from '../../redux/errors/errors.actions';

/* FIREBASE */

import { firestore } from '../../utils/firebase';

/* Material-UI */

import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';

import TextField from '@material-ui/core/TextField';

/* Styled Components */

import styled from 'styled-components';

const TextfieldEditor = ({
	subject,
	userId,
	styleColor,
	styleFontSize,
	styleFontWeight,
	styleBorderColor,
	styleBorderColorOffset,
	styleBorderRadius,
	styleBorderSize,
	styleMargin,
	styleLineHeight,
	path,
}) => {
	const dispatch = useDispatch();

	const [userDataLocal, setUserDataLocal] = useState(null);
	const user = useSelector((state) => state.user);
	const userData = useSelector((state) => state.userData);

	const editToggle = useSelector((state) => state.editing.edit);
	const editIconToggle = useSelector((state) => state.editing.icon);

	const handleEditChange = (event) => {
		setUserDataLocal({
			...userDataLocal,
			[event.target.id]: event.target.value,
		});
	};

	const handleInPageEditSubmit = (target) => {
		if (userData[target] !== userDataLocal[target]) {
			firestore
				.collection('users')
				.doc(userId)
				.set(
					{
						[target]: userDataLocal[target],
					},
					{ merge: true }
				)
				.then(dispatch(setEditToggle({ ...editToggle, [target]: false })))
				.catch((error) => {
					dispatch(addError([path, error.message]));
				});
		} else {
			dispatch(setEditToggle({ ...editToggle, [target]: false }));
		}
	};

	useEffect(() => {
		setUserDataLocal(userData);
		return () => {
			setUserDataLocal(null);
		};
	}, [userData]);

	if (user && userData && userDataLocal) {
		return (
			<Text
				onMouseOver={() =>
					user.uid === userId &&
					dispatch(setIconEditToggle({ ...editIconToggle, [subject]: true }))
				}
				onMouseLeave={() =>
					user.uid === userId &&
					dispatch(setIconEditToggle({ ...editIconToggle, [subject]: false }))
				}
				onDoubleClick={() =>
					user.uid === userId &&
					editToggle[subject] === false &&
					dispatch(setEditToggle({ ...editToggle, [subject]: true }))
				}
				styleColor={styleColor}
				styleFontSize={styleFontSize}
				styleFontWeight={styleFontWeight}
				styleBorderColor={styleBorderColor}
				styleBorderColorOffset={styleBorderColorOffset}
				styleBorderRadius={styleBorderRadius}
				styleBorderSize={styleBorderSize}
				styleMargin={styleMargin}
				styleLineHeight={styleLineHeight}
				allowHover={user?.uid === userId}
			>
				{editToggle[subject] === true ? (
					<TextEdit
						id={subject}
						value={userDataLocal?.[subject]}
						onChange={handleEditChange}
						multiline
						rowsMax={10}
						fullWidth
						styleColor={styleColor}
						styleFontSize={styleFontSize}
						styleFontWeight={styleFontWeight}
						styleLineHeight={styleLineHeight}
					/>
				) : (
					userData[subject]
				)}
				{user.uid === userId &&
					editIconToggle[subject] === true &&
					editToggle[subject] === false && (
						<EditInformation
							onClick={() =>
								dispatch(setEditToggle({ ...editToggle, [subject]: true }))
							}
						/>
					)}
				{user.uid === userId && editToggle[subject] === true && (
					<DoneInformation onClick={() => handleInPageEditSubmit(subject)} />
				)}
			</Text>
		);
	} else {
		return null;
	}
};

export default TextfieldEditor;

const Text = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 5px;
	margin: ${(props) => props.styleMargin};
	color: ${(props) => props.theme.color[`${props.styleColor}`]};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight[`${props.styleFontWeight}`]};
	font-size: ${(props) => props.theme.fontSize[`${props.styleFontSize}`]};
	border: ${(props) => props.styleBorderSize} solid
		${(props) => props.theme.color[`${props.styleBorderColorOffset}`]};
	border-radius: ${(props) => props.styleBorderRadius};
	line-height: ${(props) => props.styleLineHeight};

	${(props) =>
		props.allowHover &&
		`&:hover {
		border: ${props.styleBorderSize} solid
			${props.theme.color[`${props.styleBorderColor}`]};
		border-radius: ${props.styleBorderRadius};
		cursor: pointer;
	}`}
`;

const TextEdit = styled(TextField)`
	.MuiInputBase-root {
		color: white;
	}

	.MuiInput-underline:before {
		border-bottom: 1px solid
			${(props) => props.theme.color[`${props.styleBorderColorOffset}`]};
	}

	.MuiInput-underline:after {
		border-bottom: 1px solid
			${(props) => props.theme.color[`${props.styleBorderColor}`]};
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: 1px solid
			${(props) => props.theme.color[`${props.styleBorderColor}`]};
	}

	.MuiInputBase-inputMultiline {
		color: ${(props) => props.theme.color[`${props.styleColor}`]};
		font-family: ${(props) => props.theme.fontFamily.primary};
		font-weight: ${(props) =>
			props.theme.fontWeight[`${props.styleFontWeight}`]};
		font-size: ${(props) => props.theme.fontSize[`${props.styleFontSize}`]};
		line-height: ${(props) => props.styleLineHeight};
	}
`;

const EditInformation = styled(EditIcon)`
	color: ${(props) => props.theme.color[`${props.styleBorderColor}`]};
`;

const DoneInformation = styled(DoneIcon)`
	color: ${(props) => props.theme.color[`${props.styleBorderColor}`]};
`;
