import { DialogsActionTypes } from './dialogs.types';

export const setDialogExperience = (data) => ({
	type: DialogsActionTypes.UPDATE_EXPERIENCE,
	payload: data,
});

export const setDialogActivity = (data) => ({
	type: DialogsActionTypes.UPDATE_ACTIVITY,
	payload: data,
});

export const setDialogActivityKey = (data) => ({
	type: DialogsActionTypes.UPDATE_ACTIVITY_KEY,
	payload: data,
});

export const setDeleteActivityModal = (payload) => ({
	type: DialogsActionTypes.SET_DELETE_ACTIVITY_MODAL,
	payload: payload,
});

export const setShareModal = (payload) => ({
	type: DialogsActionTypes.SET_SHARE_MODAL,
	payload: payload,
});
