import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import {
	overwriteSkillCriteria,
	clearSkillCriteria,
} from '../../redux/skills/skills.actions';

/* FIREBASE */

/* Material-UI */

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

/* Component Dependencies */

/* Styled Components */

import styled from 'styled-components';

const animatedComponents = makeAnimated();

const Skills = ({ margin, contained, ...props }) => {
	const dispatch = useDispatch();

	const activities = useSelector((state) => state.activities);
	const selectedSkills = useSelector((state) => state.skillsSearch.skills);

	const [skills, setSkills] = useState([]);
	const [filter, setFilter] = useState([]);

	useEffect(() => {
		let s = [];

		activities.map((obj) =>
			obj.skills.split(',').map((skill) => s.push(skill))
		);

		setSkills(
			s
				.filter((v, i, a) => a.indexOf(v) === i)
				.reduce((a, v) => [...a, { value: v, label: v }], [])
				.filter((v, i, a) => a.indexOf(v) === i)
		);

		return () => {
			setSkills([]);
		};
	}, [activities]);

	useEffect(() => {
		setFilter(
			selectedSkills.map((skill) => ({
				value: skill,
				label: skill,
			}))
		);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSkills]);

	const handleChange = (event) => {
		event
			? dispatch(overwriteSkillCriteria(event.map((e) => e.value)))
			: dispatch(clearSkillCriteria());
		setFilter(event);
	};

	return (
		<SkillSelect margin={margin} contained={contained}>
			<SelectText contained={contained}>
				<SelectIcon />
				Zoek expertise
			</SelectText>
			<SelectBar
				components={animatedComponents}
				closeMenuOnSelect={false}
				options={skills}
				onChange={(event) => handleChange(event)}
				value={filter}
				isMulti
				{...props}
			/>
		</SkillSelect>
	);
};

export default Skills;

const SkillSelect = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	max-width: ${(props) => props.theme.innerWidth};
	margin: ${(props) => (props.margin ? props.margin : `0`)};

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding: 0 0 0 0;
		margin-bottom: ${(props) => (props.contained ? `20px` : `10px`)};
		width: ${(props) => (props.contained ? `100%` : `calc(100% - 60px)`)};
	}
`;

const SelectText = styled.h3`
	display: ${(props) => (props.contained === true ? `none;` : `flex;`)};
	align-items: center;
	min-width: 170px;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.small};

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		display: none;
	}
`;

const SelectIcon = styled(SearchRoundedIcon)`
	padding: 0 10px 0 0;
`;

const SelectBar = styled(Select)`
	width: 100%;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.small};

	&:focus {
		border-color: ${(props) => props.theme.backgroundColor.primary};
	}
`;
