import { ErrorsActionTypes } from './errors.types';

const INITIAL_STATE = null;

const errorsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ErrorsActionTypes.ADD_ERROR:
			return { ...state, [action.payload[0]]: action.payload[1] };

		default:
			return state;
	}
};

export default errorsReducer;
