import { ActionTypes } from './experienceSingle.types';

export const setExperienceConfirmation = (payload) => ({
	type: ActionTypes.SET_EXPERIENCE_SINGLE_MODAL,
	payload: payload,
});

export const setExperienceId = (payload) => ({
	type: ActionTypes.SET_EXPERIENCE_SINGLE_ID,
	payload: payload,
});

export const setExperienceActivityCount = (payload) => ({
	type: ActionTypes.SET_EXPERIENCE_SINGLE_ACTIVITY_COUNT,
	payload: payload,
});
