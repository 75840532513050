import React from 'react';

import { SwitchTransition, CSSTransition } from 'react-transition-group';
import './auth.css';

/* REDUX */

import { useSelector } from 'react-redux';

/* Component Dependencies */

import SignIn from '../components/Auth/SignIn';
import SignUp from '../components/Auth/SignUp';

import PageContainer from '../components/Containers/PageContainer';

function AuthPage() {
	const stage = useSelector((state) => state.authStage);

	return (
		<PageContainer>
			<SwitchTransition mode='out-in'>
				<CSSTransition
					key={stage}
					addEndListener={(node, done) => {
						node.addEventListener('transitionend', done, false);
					}}
					classNames='fade'
				>
					{stage === '/signin' ? <SignIn /> : <SignUp />}
				</CSSTransition>
			</SwitchTransition>
		</PageContainer>
	);
}

export default AuthPage;
