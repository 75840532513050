import { ActionTypes } from './experienceSingle.types';

const INITIAL_STATE = {
	experienceConfirmation: false,
	experience: null,
	activityCount: 0,
};

const experienceSingleReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.SET_EXPERIENCE_SINGLE_MODAL:
			return {
				...state,
				experienceConfirmation: action.payload,
			};

		case ActionTypes.SET_EXPERIENCE_SINGLE_ID:
			return {
				...state,
				experience: action.payload,
			};

		case ActionTypes.SET_EXPERIENCE_SINGLE_ACTIVITY_COUNT:
			return {
				...state,
				activityCount: action.payload,
			};

		default:
			return state;
	}
};

export default experienceSingleReducer;
