import React, { useState, useEffect } from 'react';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';
import { addProject } from '../../redux/activities/activities.actions';
import { setDialogActivity } from '../../redux/dialogs/dialogs.actions';

/* FIREBASE */

import { firestore } from '../../utils/firebase';

/* Material-UI */

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

/* Components */
import DialogActivityContent from './DialogActivityContent';

import { TextTitle } from '../Core/Text';

/* Styled Components */

import './DialogExperience.css';

import styled from 'styled-components';

function DialogActivity() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const experience = useSelector((state) => state.dialogs.activityKey);
	const activities = useSelector((state) => state.activities);

	const company = useSelector((state) => state.experience[experience].company);

	const [skills, setSkills] = useState([]);
	const [options, setOptions] = useState([]);

	const [values, setValues] = useState({
		activity: '',
		client: '',
		description: '',
	});

	const [errors, setErrors] = useState({
		activity: null,
		client: null,
		description: null,
	});

	const [error, setError] = useState(null);
	const [errorSnackbar, setErrorSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = (event) => {
		setValues((state) => {
			return { ...state, [event.target.id]: event.target.value };
		});
	};

	const handleChangeSelect = (event) => {
		setSkills(event);
	};

	const handleCloseError = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setErrorSnackbar(false);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		let errorCount = 0;

		setErrors({
			activity: null,
			client: null,
			description: null,
			skills: null,
		});
		setError(null);
		setErrorSnackbar(false);

		if (values.company === '') {
			setErrors((state) => {
				return { ...state, activity: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}
		if (values.description === '') {
			setErrors((state) => {
				return { ...state, description: 'Voer een geldige waarde in.' };
			});
			errorCount++;
		}

		if (skills.length === 0) {
			setErrors((state) => {
				return { ...state, skills: 'Selecteer tenminste 1 expertise.' };
			});
			errorCount++;
		}

		if (errorCount > 0) {
			return false;
		}

		const skillsValuesOnly = [skills.map((skill) => skill.label)];
		const maxSortOrder = activities.reduce((acc, val) => {
			return acc > val.sortOrder ? acc : val.sortOrder;
		}, 0);

		setIsLoading(true);

		await firestore
			.collection('users')
			.doc(user.uid)
			.collection('experience')
			.doc(experience)
			.collection('projects')
			.doc()
			.set({
				activity: values.activity,
				client: values.client,
				description: values.description,
				sortOrder:
					activities.filter((obj) => obj.experienceId === experience).length ===
					0
						? 0
						: maxSortOrder + 1,
				skills: skillsValuesOnly.join(','),
			})
			.then(setIsLoading(false))
			.then(
				dispatch(
					addProject({
						activity: values.activity,
						client: values.client,
						description: values.description,
						sortOrder: activities.length === 0 ? 0 : maxSortOrder + 1,
						skills: skillsValuesOnly.join(','),
					})
				)
			)
			.then(dispatch(setDialogActivity(false)))
			.catch((error) => {
				setError(error.message);
				setErrors({ activity: null, description: null, skills: null });
				setIsLoading(false);
				setErrorSnackbar(true);
			});
	};

	useEffect(() => {
		let s = [];

		activities.map((obj) =>
			obj.skills.split(',').map((skill) => s.push(skill))
		);

		setOptions(
			s
				.filter((v, i, a) => a.indexOf(v) === i)
				.reduce((a, v) => [...a, { value: v, label: v }], [])
				.filter((v, i, a) => a.indexOf(v) === i)
		);

		return () => {
			setOptions([]);
		};
	}, [activities]);

	return (
		<form>
			<Wrapper standalone={true}>
				<Container>
					<TextTitle>Project toevoegen aan {company}</TextTitle>
					<DialogActivityContent
						handleChange={handleChange}
						handleSubmit={handleSubmit}
						handleChangeSelect={handleChangeSelect}
						values={values}
						options={options}
						skills={skills}
						errors={errors}
						isLoading={isLoading}
					/>
				</Container>
			</Wrapper>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={errorSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseError}
				message={error}
				action={
					<React.Fragment>
						<IconButton
							size='small'
							aria-label='close'
							color='inherit'
							onClick={handleCloseError}
						>
							<CloseIcon fontSize='small' />
						</IconButton>
					</React.Fragment>
				}
			/>
		</form>
	);
}

export default DialogActivity;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-top: ${(props) => (props.standalone ? `120px` : `0`)};

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		padding: 20px;
		margin-top: ${(props) => (props.standalone ? `80px` : `0`)};
		width: calc(100% - 40px);
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 500px;
	align-items: center;

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		max-width: 400px;
	}
`;
