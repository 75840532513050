import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
	backgroundColor: {
		secondary: '#ffffff',
		secondaryFar: '#ff5a5f',
		secondaryFarHover: '#ff7478',
		secondaryClose: '#f7f7f7',
		primary: '#324359',
		primaryDark: '#3D4756',
		primaryLight: '#0097FA',
		tertiary: '#9EAFCA',
		busybeeMain: '#009FCE',
		busybeeMainHover: '#3bb7db',
		busybeeSecondary: '#f3f2ef',
	},
	color: {
		primary: '#324359',
		secondary: '#0097FA',
		tertiary: '#A1ACBD',
		quartiary: '#8a99b3',
		contrastLight: '#ffffff',
		contrastPrimary: '#ff8588',
		busybeeMain: '#009FCE',
		busybeeMainHover: '#3bb7db',
		busybeeMainHoverDark: '#008FB9',
	},
	buttonColor: {
		primary: '#D7D7D7',
	},
	fontFamily: {
		primary: 'Exo',
	},
	fontSize: {
		mini: '10px',
		helper: '0.75rem',
		timi: '12px',
		tiny: '14px',
		small: '16px',
		medium: '18px',
		large: '20px',
		huge: '24px',
		title: '30px',
		scream: '48px',
	},
	fontWeight: {
		extrabold: 900,
		bold: 800,
		semibold: 700,
		medium: 600,
		regular: 500,
		light: 400,
		extralight: 300,
		thin: 200,
	},
	breakpoint: {
		xs: '480px',
		sm: '768px',
		md: '992px',
		lg: '1120px',
	},
	innerWidth: '1120px',
};

const Theme = ({ children }) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
