import { SkillsActionTypes } from './skills.types';

const INITIAL_STATE = {
	skills: [],
};

const skillsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SkillsActionTypes.ADD_SKILL_CRITERIA:
			return {
				skills: [...state.skills, action.payload].filter(
					(val, id, array) => array.indexOf(val) === id
				),
			};

		case SkillsActionTypes.OVERWRITE_SKILL_CRITERIA:
			return {
				skills: action.payload.filter(
					(val, id, array) => array.indexOf(val) === id
				),
			};

		case SkillsActionTypes.CLEAR_SKILL_CRITERIA:
			return {
				skills: [],
			};
		default:
			return state;
	}
};

export default skillsReducer;
