import React from 'react';

/* Component Dependencies */

import InputEditor from '../PageEditor/InputEditor';

/* Styled Components */

import styled from 'styled-components';

function Header({ userId, userData, path }) {
	return (
		<Container>
			<HeaderTitle
				subject='expertise'
				userId={userId}
				styleColor='primary'
				styleFontSize='huge'
				styleFontWeight='semibold'
				styleBorderColor='primary'
				styleBorderColorOffset='contrastLight'
				styleBorderSize='2px'
				styleBorderRadius='10px'
				styleMargin='2px 0'
				path={path}
			/>
			<HeaderSubtitle
				subject='displayName'
				userId={userId}
				styleColor='primary'
				styleFontSize='medium'
				styleFontWeight='medium'
				styleBorderColor='primary'
				styleBorderColorOffset='contrastLight'
				styleBorderSize='2px'
				styleBorderRadius='10px'
				styleMargin='2px 0'
				path={path}
			/>
		</Container>
	);
}

export default Header;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: ${(props) => props.theme.innerWidth};
	width: 100%;
	align-items: left;
	justify-content: left;
	padding: 20px;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		width: calc(100% - 20px);
	}
`;

const HeaderTitle = styled(InputEditor)`
	margin: 5px 0;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 20px;
	}
`;

const HeaderSubtitle = styled(InputEditor)`
	margin: 5px 0;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 20px;
	}
`;
