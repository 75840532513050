import { UserDataActionTypes } from './userData.types';

const INITIAL_STATE = null;

const userDataReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UserDataActionTypes.SET_DATA:
			return action.payload;

		default:
			return state;
	}
};

export default userDataReducer;
