import { SkillsActionTypes } from './skills.types';

export const addSkillCriteria = (skill) => ({
	type: SkillsActionTypes.ADD_SKILL_CRITERIA,
	payload: skill,
});

export const overwriteSkillCriteria = (skills) => ({
	type: SkillsActionTypes.OVERWRITE_SKILL_CRITERIA,
	payload: skills,
});

export const clearSkillCriteria = () => ({
	type: SkillsActionTypes.CLEAR_SKILL_CRITERIA,
	payload: null,
});
