import React, { useEffect, useState } from 'react';

/* Material-UI */

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import CircularProgress from '@material-ui/core/CircularProgress';

/* Components */

import { TextInput, ButtonPositive } from '../components/Core/Form';
import { TextRegular, TextTitle, TextSubtitle } from '../components/Core/Text';

/* Styled Components */

import styled from 'styled-components';

const HomePage = () => {
	return (
		<Container>
			<Content>
				<CallToAction>
					<TextTitle>Tech that works</TextTitle>
					<TextSubtitle>interact with our solutions?</TextSubtitle>
					<TextRegular>
						Technology should accomodate end-users and solve real business
						problems. ESC provides digital solutions for every stage of
						innovation, emphasising on the collaboration between users and
						developers. Curious about the tech we have built so far?
					</TextRegular>
					<div style={{ marginTop: '1rem' }}>
						<ButtonPositive>GRATIS ACCOUNT AANMAKEN</ButtonPositive>
					</div>
				</CallToAction>
			</Content>
		</Container>
	);
};

export default HomePage;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: calc(100vh - 64px);
`;

const Content = styled.div`
	display: flex;
	max-width: ${(props) => props.theme.innerWidth};
`;

const CallToAction = styled.div`
	display: flex;
	flex: 0.5;
	flex-direction: column;
	margin-top: 2rem;
`;
