import { DialogsActionTypes } from './dialogs.types';

const INITIAL_STATE = {
	experience: false,
	activity: false,
	activityKey: null,
	deleteActivity: false,
	share: false,
};

const dialogsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DialogsActionTypes.UPDATE_EXPERIENCE:
			return {
				...state,
				experience: action.payload,
			};

		case DialogsActionTypes.UPDATE_ACTIVITY:
			return {
				...state,
				activity: action.payload,
			};

		case DialogsActionTypes.UPDATE_ACTIVITY_KEY:
			return {
				...state,
				activityKey: action.payload,
			};

		case DialogsActionTypes.SET_DELETE_ACTIVITY_MODAL:
			return {
				...state,
				deleteActivity: action.payload,
			};

		case DialogsActionTypes.SET_SHARE_MODAL:
			return {
				...state,
				share: action.payload,
			};

		default:
			return state;
	}
};

export default dialogsReducer;
