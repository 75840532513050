import { EditingActionTypes } from './editing.types';

const INITIAL_STATE = {
	edit: {
		expertise: false,
		displayName: false,
		profileText: false,
		profilePhoto: false,
	},
	icon: {
		expertise: false,
		displayName: false,
		profileText: false,
		profilePhoto: false,
	},
};

const editingReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case EditingActionTypes.SET_ICON_TOGGLE:
			return {
				...state,
				icon: action.payload,
			};
		case EditingActionTypes.SET_TOGGLE:
			return { ...state, edit: action.payload };
		default:
			return state;
	}
};

export default editingReducer;
