import React from 'react';

/* Styled Components */

import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
`;

function PageContainer({ children }) {
	return <Container>{children}</Container>;
}

export default PageContainer;
