import { ActionTypes } from './activitySingle.types';

export const setActivityConfirmation = (payload) => ({
	type: ActionTypes.SET_ACTIVITY_SINGLE_MODAL,
	payload: payload,
});

export const setActivityId = (payload) => ({
	type: ActionTypes.SET_ACTIVITY_SINGLE_ID,
	payload: payload,
});

export const setExperienceId = (payload) => ({
	type: ActionTypes.SET_ACTIVITY_SINGLE_EXPERIENCE,
	payload: payload,
});

export const setHasDropped = (payload) => ({
	type: ActionTypes.SET_ACTIVITY_HAS_DROPPED,
	payload: payload,
});
