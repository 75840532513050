import { AuthStageActionTypes } from './authStage.types';

const INITIAL_STATE = '/signin';

const authStageReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AuthStageActionTypes.CHANGE_STAGE:
			return action.payload;

		default:
			return state;
	}
};

export default authStageReducer;
