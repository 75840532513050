import React, { useState, useEffect } from 'react';

/* REDUX */

import { useSelector, useDispatch } from 'react-redux';

import { setDialogExperience } from '../../redux/dialogs/dialogs.actions';

/* Material-UI */

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

/* Component Dependencies */

import DialogExperience from '../Dialog/DialogExperience';

/* Styled Components */

import styled from 'styled-components';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function AddExperience() {
	const dispatch = useDispatch();
	const open = useSelector((state) => state.dialogs.experience);

	const handleOpen = () => {
		dispatch(setDialogExperience(true));
	};

	const handleClose = () => {
		dispatch(setDialogExperience(false));
	};

	return (
		<React.Fragment>
			<Container>
				<AddButton onClick={handleOpen} size='large'>
					<AddIcon />
				</AddButton>
			</Container>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<NavBar>
					<NavContent>
						<DialogLeftContainer>
							<IconButton
								edge='start'
								color='inherit'
								onClick={handleClose}
								aria-label='close'
							>
								<CloseDialog />
							</IconButton>
							<DialogTitle>Ervaring toevoegen</DialogTitle>
						</DialogLeftContainer>
					</NavContent>
				</NavBar>
				<DialogExperience />
			</Dialog>
		</React.Fragment>
	);
}

export default AddExperience;

const Container = styled.div`
	position: fixed;
	bottom: 20px;
	right: 20px;
`;

const AddButton = styled(Fab)`
	background-color: ${(props) => props.theme.color.busybeeMain};
	color: ${(props) => props.theme.color.contrastLight};

	&:hover {
		background-color: ${(props) => props.theme.color.busybeeMainHoverDark};
	}
`;

const NavBar = styled(AppBar)`
	display: flex;
	align-items: center;
	background-color: ${(props) => props.theme.backgroundColor.secondaryClose};
`;

const NavContent = styled(Toolbar)`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const DialogLeftContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: 30px;
`;

const CloseDialog = styled(CloseIcon)`
	color: ${(props) => props.theme.backgroundColor.primary};
`;

const DialogTitle = styled.p`
	color: ${(props) => props.theme.backgroundColor.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.regular};
	font-size: ${(props) => props.theme.fontSize.huge};
	margin-left: 10px;
`;
