import { ExperienceActionTypes } from './experience.types';

const INITIAL_STATE = null;

const experienceReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ExperienceActionTypes.ADD_EXPERIENCE:
			return { ...state, [action.payload[0]]: action.payload[1] };

		case ExperienceActionTypes.REMOVE_EXPERIENCE:
			return Object.fromEntries(
				Object.entries(state).filter(([key]) => !key.includes(action.payload))
			);

		default:
			return state;
	}
};

export default experienceReducer;
