import { ActionTypes } from './activities.types';

export const addProject = (payload) => ({
	type: ActionTypes.ADD_PROJECT,
	payload: payload,
});

export const updateProject = (payload) => ({
	type: ActionTypes.UPDATE_PROJECT,
	payload: payload,
});

export const removeProject = (payload) => ({
	type: ActionTypes.REMOVE_PROJECT,
	payload: payload,
});
